/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ILoginPageLayoutProps } from "./types";
import classNames from "classnames";
import { LogoSuccessIcon } from "../../../svg-icons/icon-logo-success"
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./login-page-layout.module.scss";

function LoginPageLayout({
  children,
  pageTitle,
  // activeTerminalInfo,
  gettingTerminalInfo
}: ILoginPageLayoutProps) {
  const { t } = useTranslation();
  const terminalData = localStorage.getItem('_terminal_Login_info');
  const activeTerminalInfo = JSON.parse(terminalData)
  
  return (

    <div
      className={classNames({
        [`${styles.main_wrapper}`]: true,
      })}
    >
      <div className={styles.app_content}>
        <div className={styles.logo_container}>
          <LogoSuccessIcon />
          {!gettingTerminalInfo && activeTerminalInfo?.terminalId ? <span className={styles.seller_description}>
            <div>
              {t('Terminal')} #{activeTerminalInfo.terminalId}
            </div>
            <div>
              IDNO: {activeTerminalInfo.idno}
            </div>
            <div>
              {activeTerminalInfo.placeName}
            </div>
            <div>
              {activeTerminalInfo.placeAddres}
            </div>
          </span> : gettingTerminalInfo && <CircularProgress className={styles.circular_progress} size={26} />}
        </div>



        <div className={styles.page_title}>
          {pageTitle}
        </div>
        <div className={styles.page_content}>
          {children}
        </div>

      </div>
    </div>
  );
}

export default LoginPageLayout;
