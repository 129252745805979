
import React from "react";

export const BackButtonIcon = ({ onClick, className, id  = "" }) => {
    return (
        <svg onClick={onClick} className={className} id={id} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#ABB4C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
