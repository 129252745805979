import React from 'react';

export const LogoSuccessIcon = () => {
    return (
        <svg width="158" height="29" viewBox="0 0 158 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_351_2590)">
                <path d="M126.084 13.6582H127.587L127.38 14.6403C127.583 14.288 127.826 14.0202 128.113 13.8368C128.401 13.6534 128.719 13.5641 129.074 13.5641C129.595 13.5641 129.986 13.7258 130.247 14.0515C130.507 14.3749 130.638 14.8044 130.638 15.3377C130.638 15.5742 130.611 15.8252 130.56 16.0858L130.551 16.1292C130.423 16.7494 130.172 17.2417 129.8 17.6061C129.426 17.9704 128.968 18.1538 128.422 18.1538C128.034 18.1538 127.715 18.0549 127.462 17.8594C127.209 17.664 127.044 17.3865 126.962 17.0341L126.953 17.1379L126.415 19.7006H124.807L126.084 13.663V13.6582ZM127.421 16.8073C127.549 16.9618 127.727 17.0366 127.959 17.0366C128.191 17.0366 128.384 16.9642 128.536 16.8194C128.69 16.6746 128.794 16.4671 128.852 16.1944L128.973 15.6128C128.997 15.5211 129.006 15.4222 129.006 15.3184C129.006 15.1109 128.951 14.9468 128.842 14.831C128.731 14.7151 128.577 14.6572 128.374 14.6572C128.154 14.6572 127.957 14.7248 127.783 14.8623C127.609 14.9975 127.474 15.1809 127.375 15.4101C127.276 15.6394 127.228 15.8855 127.228 16.1534V16.1703C127.228 16.4429 127.291 16.6553 127.418 16.8097L127.421 16.8073Z" fill="black" />
                <path d="M132.421 11.8774H134.028L132.715 18.0453H131.108L132.421 11.8774Z" fill="black" />
                <path d="M133.999 16.6625C134.057 16.385 134.199 16.1558 134.424 15.9772C134.651 15.7987 134.947 15.6828 135.319 15.6297L137.187 15.3788L137.23 15.1881C137.242 15.1182 137.247 15.0675 137.247 15.0313C137.247 14.8913 137.199 14.7851 137.1 14.7103C137.001 14.6355 136.856 14.5969 136.666 14.5969C136.475 14.5969 136.292 14.6476 136.135 14.7489C135.978 14.8503 135.862 15.0071 135.787 15.2219H134.414C134.525 14.6886 134.788 14.2784 135.208 13.9888C135.628 13.6992 136.154 13.5545 136.784 13.5545C137.414 13.5545 137.945 13.6727 138.299 13.9068C138.656 14.1408 138.833 14.4738 138.833 14.901C138.833 15.0047 138.818 15.123 138.789 15.2581L138.198 18.0477H136.721L136.885 17.2658C136.745 17.5313 136.533 17.746 136.243 17.9077C135.954 18.0694 135.623 18.1514 135.254 18.1514C134.848 18.1514 134.53 18.0477 134.298 17.8377C134.067 17.6302 133.951 17.3575 133.951 17.0221C133.951 16.9063 133.963 16.8025 133.985 16.7084L133.994 16.665L133.999 16.6625ZM135.546 16.8266C135.546 16.9473 135.587 17.0438 135.667 17.1138C135.749 17.1838 135.86 17.2176 136.004 17.2176C136.236 17.2176 136.439 17.1548 136.612 17.0269C136.786 16.899 136.897 16.7349 136.943 16.5322L137.03 16.1317L136.118 16.2716C135.949 16.3006 135.819 16.3537 135.727 16.4285C135.635 16.5033 135.58 16.5853 135.563 16.6722L135.553 16.7156C135.548 16.7398 135.543 16.776 135.543 16.8291L135.546 16.8266ZM136.709 11.8146C136.709 12.0125 136.75 12.1573 136.834 12.2538C136.919 12.3503 137.049 12.3962 137.23 12.3962C137.433 12.3962 137.599 12.3286 137.73 12.1959C137.86 12.0631 137.966 11.8629 138.046 11.5974H139.028C138.895 12.1428 138.661 12.5602 138.323 12.8522C137.988 13.1442 137.595 13.2914 137.141 13.2914C136.719 13.2914 136.383 13.1756 136.132 12.9439C135.884 12.7123 135.758 12.3962 135.758 11.998C135.758 11.887 135.773 11.7543 135.802 11.5974H136.723C136.711 11.7012 136.707 11.7736 136.707 11.8146H136.709Z" fill="black" />
                <path d="M140.264 17.8112C140.08 17.6543 139.991 17.4251 139.991 17.1259C139.991 17.0342 140.005 16.9087 140.034 16.7518L140.478 14.6669H139.583L139.8 13.6582H140.686L140.946 12.4155H142.554L142.293 13.6582H143.422L143.205 14.6669H142.076L141.651 16.6746C141.646 16.6988 141.641 16.7277 141.641 16.7615C141.641 16.9015 141.716 16.969 141.868 16.969H142.754L142.527 18.0453H141.067C140.715 18.0453 140.447 17.9681 140.264 17.8112ZM140.346 18.4458H141.979L140.927 20.3739H139.684L140.346 18.4458Z" fill="black" />
                <path d="M144.436 13.6582H146.043L145.114 18.0453H143.507L144.436 13.6582ZM144.783 11.9208H146.444L146.166 13.1804H144.516L144.786 11.9208H144.783Z" fill="black" />
                <path d="M126.084 23.4314H127.691L126.762 27.8184H125.155L126.084 23.4314ZM126.432 21.6939H128.092L127.814 22.9536H126.164L126.434 21.6939H126.432Z" fill="black" />
                <path d="M128.9 23.4313H130.404L130.203 24.3604C130.377 24.0419 130.614 23.7885 130.915 23.6003C131.217 23.412 131.543 23.3179 131.897 23.3179C132.298 23.3179 132.612 23.4289 132.841 23.6534C133.07 23.8754 133.184 24.1722 133.184 24.5438C133.184 24.6476 133.169 24.7779 133.14 24.9347L132.522 27.8184H130.915L131.47 25.2219C131.482 25.1398 131.487 25.0867 131.487 25.0578C131.487 24.9178 131.446 24.8117 131.362 24.7368C131.277 24.662 131.157 24.6234 131.002 24.6234C130.775 24.6234 130.582 24.6886 130.42 24.8189C130.259 24.9492 130.15 25.1278 130.1 25.3522L129.578 27.8184H127.971L128.9 23.4313Z" fill="black" />
                <path d="M134.327 27.5529C133.946 27.302 133.729 26.9424 133.676 26.4791H135.013C135.058 26.6408 135.167 26.7687 135.338 26.8604C135.51 26.9521 135.725 27.0003 135.985 27.0003C136.193 27.0003 136.369 26.969 136.511 26.9038C136.654 26.8411 136.736 26.7566 136.76 26.6528L136.769 26.6094C136.81 26.4357 136.707 26.3319 136.456 26.2957L135.534 26.1557C135.046 26.0809 134.682 25.941 134.438 25.7383C134.195 25.5356 134.074 25.2629 134.074 24.9226C134.074 24.7947 134.086 24.6765 134.108 24.5655L134.117 24.522C134.192 24.1504 134.414 23.8584 134.781 23.6413C135.148 23.4241 135.63 23.3155 136.226 23.3155C136.822 23.3155 137.332 23.4361 137.715 23.6799C138.099 23.9236 138.326 24.2856 138.389 24.7658H137.042C136.996 24.6089 136.895 24.4859 136.738 24.3917C136.581 24.2976 136.393 24.2518 136.173 24.2518C135.988 24.2518 135.828 24.2807 135.691 24.3387C135.556 24.3966 135.476 24.4738 135.452 24.5727L135.442 24.6162C135.408 24.7851 135.512 24.8864 135.756 24.9202L136.641 25.0505C137.643 25.2001 138.145 25.6152 138.145 26.2933C138.145 26.3922 138.13 26.5129 138.101 26.6577L138.092 26.7011C138.01 27.0896 137.778 27.3912 137.397 27.6036C137.016 27.816 136.516 27.9245 135.903 27.9245C135.232 27.9245 134.704 27.7991 134.322 27.5457L134.327 27.5529Z" fill="black" />
                <path d="M139.713 27.5843C139.53 27.4275 139.441 27.1982 139.441 26.899C139.441 26.8073 139.455 26.6818 139.484 26.525L139.928 24.44H139.033L139.25 23.4314H140.136L140.396 22.1886H142.003L141.743 23.4314H142.872L142.655 24.44H141.526L141.101 26.4478C141.096 26.4719 141.091 26.5008 141.091 26.5346C141.091 26.6746 141.166 26.7422 141.318 26.7422H142.204L141.977 27.8184H140.517C140.165 27.8184 139.897 27.7412 139.713 27.5843Z" fill="black" />
                <path d="M142.928 26.4357C142.986 26.1582 143.128 25.9289 143.352 25.7503C143.579 25.5718 143.876 25.4559 144.248 25.4029L146.115 25.1519L146.159 24.9613C146.171 24.8913 146.176 24.8406 146.176 24.8044C146.176 24.6644 146.127 24.5583 146.029 24.4835C145.93 24.4087 145.785 24.37 145.594 24.37C145.404 24.37 145.22 24.4207 145.063 24.5221C144.906 24.6234 144.791 24.7803 144.716 24.995H143.343C143.454 24.4617 143.717 24.0515 144.137 23.7619C144.557 23.4724 145.083 23.3276 145.712 23.3276C146.342 23.3276 146.873 23.4458 147.228 23.6799C147.585 23.914 147.761 24.247 147.761 24.6741C147.761 24.7779 147.747 24.8961 147.718 25.0312L147.126 27.8208H145.65L145.814 27.039C145.674 27.3044 145.461 27.5192 145.172 27.6808C144.882 27.8425 144.552 27.9246 144.182 27.9246C143.777 27.9246 143.459 27.8208 143.227 27.6109C142.995 27.4033 142.879 27.1307 142.879 26.7952C142.879 26.6794 142.891 26.5756 142.913 26.4815L142.923 26.4381L142.928 26.4357ZM144.474 26.5998C144.474 26.7204 144.515 26.8169 144.595 26.8869C144.677 26.9569 144.788 26.9907 144.933 26.9907C145.165 26.9907 145.367 26.9279 145.541 26.8001C145.715 26.6722 145.826 26.5081 145.872 26.3054L145.959 25.9048L145.046 26.0447C144.877 26.0737 144.747 26.1268 144.655 26.2016C144.564 26.2764 144.508 26.3585 144.491 26.4453L144.482 26.4888C144.477 26.5129 144.472 26.5491 144.472 26.6022L144.474 26.5998Z" fill="black" />
                <path d="M149.182 23.4313H150.686L150.486 24.3604C150.659 24.0419 150.896 23.7885 151.197 23.6003C151.499 23.412 151.825 23.3179 152.18 23.3179C152.58 23.3179 152.894 23.4289 153.123 23.6534C153.352 23.8754 153.466 24.1722 153.466 24.5438C153.466 24.6476 153.451 24.7779 153.422 24.9347L152.805 27.8184H151.197L151.752 25.2219C151.765 25.1398 151.769 25.0867 151.769 25.0578C151.769 24.9178 151.728 24.8117 151.644 24.7368C151.559 24.662 151.439 24.6234 151.284 24.6234C151.057 24.6234 150.864 24.6886 150.703 24.8189C150.541 24.9492 150.432 25.1278 150.382 25.3522L149.861 27.8184H148.253L149.182 23.4313Z" fill="black" />
                <path d="M154.841 27.5843C154.658 27.4275 154.569 27.1982 154.569 26.899C154.569 26.8073 154.583 26.6818 154.612 26.525L155.056 24.44H154.161L154.378 23.4314H155.264L155.524 22.1886H157.131L156.871 23.4314H158L157.783 24.44H156.654L156.229 26.4478C156.224 26.4719 156.219 26.5008 156.219 26.5346C156.219 26.6746 156.294 26.7422 156.446 26.7422H157.332L157.105 27.8184H155.645C155.293 27.8184 155.025 27.7412 154.841 27.5843Z" fill="black" />
                <path d="M68.6446 12.6496C68.6446 11.8919 68.4298 11.3151 68.0051 10.9194C67.578 10.5236 66.9385 10.3258 66.0843 10.3258C64.8826 10.3258 63.8546 10.6805 63.0027 11.3924C62.1485 12.1042 61.5959 13.0767 61.3425 14.3098V14.2616L58.4492 27.7774H49.6775L52.7132 13.5979C52.7759 13.1563 52.8073 12.8378 52.8073 12.6496C52.8073 11.9232 52.5853 11.3537 52.1437 10.9435C51.7021 10.5333 51.0699 10.3282 50.247 10.3282C49.0453 10.3282 48.0173 10.6757 47.1654 11.3706C46.3112 12.0656 45.7417 13.0478 45.457 14.3098L42.6119 27.7774H33.8402L38.9608 3.82964H47.1172L46.0265 8.85617C46.9748 7.11872 48.2562 5.74325 49.8681 4.72974C51.4801 3.71864 53.2344 3.21189 55.1311 3.21189C57.0279 3.21189 58.5361 3.68486 59.7547 4.63321C60.9709 5.58157 61.7069 6.87741 61.9603 8.52075C62.9086 6.90878 64.19 5.62018 65.802 4.65493C67.4139 3.68968 69.1369 3.20947 70.9709 3.20947C73.1209 3.20947 74.8125 3.81034 76.0432 5.01207C77.2763 6.2138 77.8917 7.82577 77.8917 9.85037C77.8917 10.4512 77.812 11.1776 77.6552 12.0318L74.2889 27.775H65.5172L68.5529 13.5955C68.6157 13.3735 68.647 13.0574 68.647 12.6472L68.6446 12.6496Z" fill="black" />
                <path d="M84.2937 3.82959H93.0653L87.9906 27.7774H79.2189L84.2937 3.82959Z" fill="black" />
                <path d="M93.7049 20.2364C94.0211 18.7185 94.7957 17.4709 96.0288 16.4912C97.2619 15.5115 98.8907 14.8792 100.913 14.5945L111.108 13.219L111.345 12.1765C111.408 11.7977 111.439 11.5129 111.439 11.3223C111.439 10.5622 111.171 9.97819 110.633 9.56796C110.095 9.15773 109.306 8.9502 108.263 8.9502C107.221 8.9502 106.224 9.22771 105.37 9.78032C104.516 10.3329 103.883 11.1968 103.473 12.3648H95.9805C96.5814 9.45696 98.0268 7.21276 100.319 5.63217C102.612 4.05157 105.481 3.26007 108.927 3.26007C112.373 3.26007 115.256 3.89955 117.201 5.18091C119.146 6.46228 120.119 8.27212 120.119 10.6104C120.119 11.1799 120.039 11.8291 119.882 12.5554L116.659 27.7774H108.596L109.496 23.5085C108.739 24.9637 107.568 26.134 105.988 27.0172C104.407 27.9028 102.605 28.3444 100.582 28.3444C98.3695 28.3444 96.6296 27.7749 95.3652 26.6384C94.1007 25.4994 93.4684 24.0153 93.4684 22.1813C93.4684 21.5491 93.5312 20.9796 93.6591 20.4753L93.7049 20.2388V20.2364ZM102.146 21.1364C102.146 21.8001 102.368 22.3213 102.81 22.7001C103.251 23.079 103.869 23.2696 104.658 23.2696C105.923 23.2696 107.028 22.9222 107.976 22.2272C108.924 21.5322 109.525 20.6321 109.779 19.5245L110.252 17.343L105.273 18.1007C104.356 18.26 103.645 18.5448 103.14 18.955C102.633 19.3652 102.334 19.8092 102.24 20.2822L102.192 20.5187C102.16 20.6466 102.144 20.8517 102.144 21.1364H102.146Z" fill="black" />
                <path d="M9.55223 13.1515C13.8621 14.0444 17.3949 12.1694 18.1453 8.54968C18.8958 4.93 16.4151 1.72056 12.0184 0.810812C7.79548 -0.0651501 4.4509 1.75917 3.68353 5.4633C2.93305 9.08298 5.2424 12.2586 9.55223 13.1515Z" fill="#A88FF5" />
                <path d="M12.7979 22.9511C13.6907 18.6413 11.8157 15.1085 8.19607 14.358C4.57639 13.6075 1.36694 16.0882 0.457197 20.4849C-0.416351 24.7079 1.40797 28.0501 5.1121 28.8174C8.73178 29.5679 11.9074 27.2586 12.8003 22.9487L12.7979 22.9511Z" fill="#669999" />
                <path d="M23.1308 15.8204C18.821 14.9275 15.2882 16.8025 14.5377 20.4222C13.7872 24.0419 16.2679 27.2513 20.6646 28.161C24.8876 29.0346 28.2297 27.2103 28.9971 23.5061C29.7476 19.8865 27.4382 16.7108 23.1284 15.818L23.1308 15.8204Z" fill="#B8753B" />
                <path d="M19.8707 6.00624C18.9779 10.3161 20.8529 13.8489 24.4726 14.5994C28.0922 15.3498 31.3017 12.8691 32.2114 8.47245C33.085 4.24949 31.2607 0.90732 27.5565 0.139948C23.9369 -0.610531 20.7612 1.69882 19.8683 6.00865L19.8707 6.00624Z" fill="#FF7859" />
            </g>
            <defs>
                <clipPath id="clip0_351_2590">
                    <rect width="157.76" height="28.9574" fill="white" transform="translate(0.23999)" />
                </clipPath>
            </defs>
        </svg>
    );
};