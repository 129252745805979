import React from 'react';
import { mockQrCode } from "../../constants/data"
export const QrCodeIcon = ({ qrCode = mockQrCode }) => {
    return (
        <svg width="258" height="259" viewBox="0 0 258 259" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <rect y="0.686646" width="258" height="258" rx="22" fill="#FDF9F2" />
            <rect x="14.8489" y="15.5356" width="228.302" height="228.302" fill="url(#pattern0_857_31591)" />
            <defs>
                <pattern id="pattern0_857_31591" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_857_31591" transform="translate(0 -0.00150522) scale(0.00235849)" />
                </pattern>
                <image id="image0_857_31591" width="424" height="427" xlinkHref={`data:image/png;base64,${qrCode}`} />
            </defs>
        </svg>

    );
};
