import { getUserData } from "../services/Auth";
import {
  SET_IS_AUTH_FETCHING,
  FETCH_TOKEN_SUCCESS
} from "../actions";
import { AnyAction } from "redux";

const initialState = {
  authData: getUserData(),
  tokenTerminalId: {},
  showBackButton: false,
  isAuthFetching: false,
};

export default function addressesReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case SET_IS_AUTH_FETCHING:
      return {
        ...state,
        isAuthFetching: action.payload
      };
    case FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        tokenTerminalId: action.payload
      };

    default:
      return state;
  }
}
