

import React from 'react';

export const NoTransactionsIcon = () => {
    return (
        <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.4071 34.4962C53.4071 32.8907 52.1056 31.5892 50.5002 31.5892C48.8947 31.5892 47.5932 32.8907 47.5932 34.4962L47.5932 48.0621C47.5932 52.8785 51.4977 56.783 56.3141 56.783H62.1281C63.7335 56.783 65.035 55.4815 65.035 53.876C65.035 52.2705 63.7335 50.969 62.1281 50.969H56.3141C54.7086 50.969 53.4071 49.6675 53.4071 48.0621L53.4071 34.4962Z" fill="#C7C5CD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M50.5002 8.33337C27.4883 8.33337 8.8335 26.9882 8.8335 50C8.8335 73.0119 27.4883 91.6667 50.5002 91.6667C73.512 91.6667 92.1668 73.0119 92.1668 50C92.1668 26.9882 73.512 8.33337 50.5002 8.33337ZM14.6474 50C14.6474 30.1991 30.6993 14.1473 50.5002 14.1473C70.3011 14.1473 86.3529 30.1991 86.3529 50C86.3529 69.8009 70.3011 85.8527 50.5002 85.8527C30.6993 85.8527 14.6474 69.8009 14.6474 50Z" fill="#C7C5CD" />
        </svg>
    );
};