

import React from 'react';

export const LanguageIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9783 2C7.45828 2 2.98828 6.48 2.98828 12C2.98828 17.52 7.45828 22 12.9783 22C18.5083 22 22.9883 17.52 22.9883 12C22.9883 6.48 18.5083 2 12.9783 2ZM19.9083 8H16.9583C16.6383 6.75 16.1783 5.55 15.5783 4.44C17.4183 5.07 18.9483 6.35 19.9083 8ZM12.9883 4.04C13.8183 5.24 14.4683 6.57 14.8983 8H11.0783C11.5083 6.57 12.1583 5.24 12.9883 4.04ZM5.24828 14C5.08828 13.36 4.98828 12.69 4.98828 12C4.98828 11.31 5.08828 10.64 5.24828 10H8.62828C8.54828 10.66 8.48828 11.32 8.48828 12C8.48828 12.68 8.54828 13.34 8.62828 14H5.24828ZM6.06828 16H9.01828C9.33828 17.25 9.79828 18.45 10.3983 19.56C8.55828 18.93 7.02828 17.66 6.06828 16ZM9.01828 8H6.06828C7.02828 6.34 8.55828 5.07 10.3983 4.44C9.79828 5.55 9.33828 6.75 9.01828 8ZM12.9883 19.96C12.1583 18.76 11.5083 17.43 11.0783 16H14.8983C14.4683 17.43 13.8183 18.76 12.9883 19.96ZM15.3283 14H10.6483C10.5583 13.34 10.4883 12.68 10.4883 12C10.4883 11.32 10.5583 10.65 10.6483 10H15.3283C15.4183 10.65 15.4883 11.32 15.4883 12C15.4883 12.68 15.4183 13.34 15.3283 14ZM15.5783 19.56C16.1783 18.45 16.6383 17.25 16.9583 16H19.9083C18.9483 17.65 17.4183 18.93 15.5783 19.56ZM17.3483 14C17.4283 13.34 17.4883 12.68 17.4883 12C17.4883 11.32 17.4283 10.66 17.3483 10H20.7283C20.8883 10.64 20.9883 11.31 20.9883 12C20.9883 12.69 20.8883 13.36 20.7283 14H17.3483Z" fill="#A1ABB2" />
        </svg>

    );
};