import React, { useState, useEffect } from "react";
import { ITransactions } from "../../interfaces";
import classNames from "classnames"
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { NAV, amountFormatter, capitalizeFirstLetter } from "../../constants"
import CircularProgress from '@mui/material/CircularProgress';
import { Redirect } from "react-router-dom";
import { TransactionCompleteIcon } from "../../svg-icons/icon-transaction-complete"
import { TransactionReturnedIcon } from "../../svg-icons/icon-transaction-returned"
import { useSelector, useDispatch } from "react-redux";
import { getDayStatus, getRefreshTransactionsList } from "../../api/selectors/mainSelector"
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { DayIsClosedIcon } from "../../svg-icons/icon-day-is-closed"
import { NoTransactionsIcon } from "../../svg-icons/icon-no-transactions"
import axios from "../../api/services/Api"
import { setShowModalOpenCloseDay, setShowReloadButton, setRefreshTransactionsList } from "../../api/actionCreators/mainActionCreator"
import moment from "moment"
import styles from "./transactions.module.scss";

const Transactions = (props: ITransactions) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false)
  const [redirectId, setRedirectId] = useState(null)

  const [transactionsList, setTransactionsList] = useState([])

  const isDayOpen = useSelector(getDayStatus);
  const refreshTransactionsList = useSelector(getRefreshTransactionsList);
  const dayState = localStorage.getItem('_day_state');
  const shiftId = localStorage.getItem('_shift_id');


  useEffect(() => {
    dispatch(setRefreshTransactionsList(true))
    dispatch(setShowReloadButton(true))
  }, [dispatch])

  useEffect(() => () => {
    dispatch(setRefreshTransactionsList(false))
    dispatch(setShowReloadButton(false))
  }, [dispatch]);

  useEffect(() => {
    if (dayState === "opened" && refreshTransactionsList) {
      setIsLoading(true);
      axios
        .get(`/pos/api/v1/tx?shiftId=${shiftId}`)
        .then(res => {
          const { result, txList } = res.data

          if (result === "success") {
            setTransactionsList(txList)
            dispatch(setRefreshTransactionsList(false))
          } else {
            enqueueSnackbar(t(capitalizeFirstLetter(result)))
          }

          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [dayState, shiftId, t, refreshTransactionsList, dispatch])

  const showTransactionDetails = (id) => {
    setIsLoading(true)
    setTimeout(() => {
      setRedirectId(id)
      setIsLoading(false)
    }, 2000);
  }

  const transactionsIsLoading = () => {
    return (
      <div className={styles.transaction_is_loading}>
        <CircularProgress size={50} />
      </div>
    )
  }

  const renderNotransactionsContent = () => {
    return (
      <div className={styles.no_transactions}>
        <NoTransactionsIcon />
        {t("No_transactions")}
      </div>
    )
  }

  const renderTransactionsLines = () => {
    const transactionsContent = transactionsList.map((item, index) => {
      const { amount, ccy, date, id, type } = item
      return (
        <div className={styles.transaction_line} onClick={() => showTransactionDetails(id)} key={index}>
          <div className={styles.number_date}>
            <div className={styles.number}>
              #{id}
            </div>
            <div className={styles.date}>
              {moment(date).locale("ru").format("HH:mm, DD.MM.YYYY")}
            </div>
          </div>
          <div className={styles.amount_curr}>
            <span>{amountFormatter(amount)} <span className={styles.uppercase}>{ccy}</span></span>
            <span>
              {type === "paid" ?
                <TransactionCompleteIcon /> :
                type && <TransactionReturnedIcon />}
            </span>
          </div>
        </div>
      )
    })

    const content = transactionsList.length === 0 ? renderNotransactionsContent() : transactionsContent

    return content
  }

  const renderTerminalCloseDay = () => {
    return (

      <div className={styles.transactions_day_is_closed_container}>

        <div className={styles.terminals_day_is_closed}>
          <DayIsClosedIcon />
          <div className={styles.terminals_day_is_closed_title}>
            {t("Day_is_closed")}
          </div>
          <div className={styles.terminals_day_is_closed_description}>
            {t('Get_day_started')}
          </div>
          <div className={styles.btn_container} onClick={() => dispatch(setShowModalOpenCloseDay(true))}>
            <div
              className={classNames({
                [`${styles.day_action_button}`]: true,
                [`${styles.is_open}`]: isDayOpen,
              })}
            >
              {isDayOpen ? t("Close_day") : t("Open_day")}
            </div>
          </div>
        </div>
      </div>

    )
  }

  return (
    <AppMainLayout activeNav={NAV.TRANSACTIONS}>
      {redirectId && <Redirect to={`/app/transactions/${redirectId}`} />}
      {isLoading && transactionsIsLoading()}
      {(isDayOpen || dayState === "opened") ? renderTransactionsLines() : renderTerminalCloseDay()}
    </AppMainLayout>
  );
};
export default Transactions;
