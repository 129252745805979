import React from 'react';

export const LogoIcon = ({ className }) => {
    return (
        <svg className={className} width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_351_1621)">
                <path d="M0 19.5C0 26.6185 1.50911 39 17 39C32.4909 39 33.9994 26.6185 34 19.5C34 12.3815 32.4909 0 17 0C1.50911 0 0 12.3815 0 19.5Z" fill="white" />
                <path d="M15.2724 30.2927C15.2724 30.1298 15.228 30.0066 15.1391 29.9212C15.0503 29.8364 14.917 29.7943 14.7399 29.7943C14.4902 29.7943 14.2765 29.8704 14.0988 30.0233C13.9212 30.1763 13.8059 30.3843 13.7537 30.6487V30.6388L13.1522 33.5377H11.3279L11.9594 30.4964C11.9726 30.4017 11.9792 30.3335 11.9792 30.2927C11.9792 30.1366 11.933 30.0147 11.8412 29.9268C11.7493 29.8388 11.6179 29.7943 11.4468 29.7943C11.1971 29.7943 10.9834 29.8692 10.8057 30.0178C10.628 30.167 10.5097 30.3775 10.4503 30.6487L9.85844 33.5377H8.03418L9.09908 28.4005H10.7955L10.5686 29.4785C10.7661 29.1057 11.032 28.8104 11.3675 28.5937C11.7025 28.377 12.0675 28.268 12.4625 28.268C12.8575 28.268 13.1708 28.3695 13.4241 28.5733C13.6768 28.777 13.8299 29.055 13.8827 29.4073C14.0802 29.0612 14.3462 28.785 14.6817 28.5782C15.0167 28.3714 15.375 28.268 15.7568 28.268C16.204 28.268 16.5558 28.3968 16.8121 28.6544C17.0684 28.9119 17.1969 29.2581 17.1969 29.6921C17.1969 29.8209 17.1807 29.9769 17.1477 30.1602L16.4471 33.5371H14.6229L15.2544 30.4958C15.2676 30.4481 15.2742 30.3806 15.2742 30.2921L15.2724 30.2927Z" fill="black" />
                <path d="M18.5265 28.4005H20.3507L19.2955 33.5377H17.4712L18.5265 28.4005Z" fill="black" />
                <path d="M20.4834 31.9198C20.5495 31.5942 20.7103 31.3267 20.9667 31.1162C21.223 30.9063 21.5615 30.7701 21.9823 30.7094L24.1025 30.4147L24.1518 30.1911C24.165 30.11 24.1716 30.0487 24.1716 30.0079C24.1716 29.845 24.1157 29.72 24.0041 29.6314C23.8924 29.5435 23.728 29.4989 23.5113 29.4989C23.2946 29.4989 23.0875 29.5583 22.9098 29.6772C22.7321 29.7961 22.6006 29.9806 22.5154 30.2314H20.9571C21.0819 29.6073 21.3827 29.1262 21.8593 28.7869C22.3359 28.4476 22.9326 28.2785 23.6493 28.2785C24.3661 28.2785 24.9657 28.416 25.3703 28.6903C25.7749 28.9652 25.9772 29.3534 25.9772 29.8549C25.9772 29.9769 25.9604 30.1162 25.928 30.2722L25.2575 33.5377H23.5809L23.7682 32.622C23.6103 32.934 23.3672 33.1848 23.0382 33.3749C22.7093 33.5644 22.3347 33.6597 21.9139 33.6597C21.4535 33.6597 21.0921 33.5377 20.8292 33.2938C20.5663 33.0498 20.4348 32.731 20.4348 32.3378C20.4348 32.2022 20.448 32.0802 20.4744 31.9719L20.484 31.9211L20.4834 31.9198ZM22.2387 32.113C22.2387 32.2554 22.2849 32.3675 22.3767 32.4486C22.4686 32.5303 22.597 32.5706 22.7615 32.5706C23.0244 32.5706 23.2543 32.4963 23.4518 32.3471C23.6493 32.1979 23.7742 32.0047 23.8264 31.7675L23.9249 31.2994L22.8894 31.4623C22.6985 31.4963 22.5508 31.5576 22.4458 31.6456C22.3407 31.7335 22.2783 31.8288 22.2585 31.9304L22.2483 31.9811C22.2417 32.0084 22.2381 32.0523 22.2381 32.1136L22.2387 32.113Z" fill="black" />
                <path d="M12.2452 14.8358C15.2658 15.481 17.7432 14.1256 18.269 11.5078C18.7943 8.89122 17.0553 6.56935 13.974 5.91179C11.014 5.27962 8.66992 6.59845 8.13207 9.27758C7.60622 11.8954 9.22458 14.1907 12.2452 14.8364V14.8358Z" fill="#A88FF5" />
                <path d="M14.5215 21.9215C15.147 18.8059 13.833 16.2506 11.295 15.7082C8.75817 15.1665 6.50711 16.9602 5.86961 20.1384C5.25672 23.1915 6.53533 25.6093 9.13275 26.1641C11.6707 26.7065 13.896 25.0372 14.5221 21.9215H14.5215Z" fill="#669999" />
                <path d="M21.7656 16.7658C18.745 16.1206 16.2676 17.476 15.7418 20.0938C15.2166 22.7104 16.9556 25.0322 20.0368 25.6898C22.9968 26.322 25.3409 25.0031 25.8788 22.324C26.4046 19.7062 24.7863 17.4109 21.7656 16.7651V16.7658Z" fill="#B8753B" />
                <path d="M19.4785 9.67013C18.853 12.7858 20.1671 15.3411 22.705 15.8835C25.2418 16.4252 27.4929 14.6315 28.1304 11.4533C28.7433 8.40022 27.4647 5.98238 24.8673 5.42761C22.3293 4.88522 20.104 6.55449 19.4779 9.67013H19.4785Z" fill="#FF7859" />
            </g>
            <defs>
                <clipPath id="clip0_351_1621">
                    <rect width="34" height="39" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};