// Used for manipulations with local storage
const SESSION_TOKEN = "_session_token";
const USER_DATA = "_user_data";
const SESSION_EXPIRED_TIME = "_expired_time"
const REFRESH_TOKEN = "_refresh_token"
const FETCH_TOKEN = "_fetch_token"
const MAINTENANCE_MODE = "_maintenance_mode"
const TERMINAL_ID = "_terminal_id"
const SHIFT_ID = "_shift_id"
const DAY_STATE = "_day_state"
const SHOW_LOGIN_FORM = "_show_login_form"
const SELLER_INFO = "_seller_info"
const TERMINAL_ERROR = "_terminal_error"
const X_POS_APP_VERSION = "x_pos_app_version"
const X_INSTALLATION_ID = "x_installation_id"
const X_PLATFORM_VERSION = "x_platform_version"
const X_PLATFORM_TYPE = "x_platform_type"
const X_DEVICE = "x_device"
const X_DEVICE_APP_VERSION = "x_device_app_version"
const QR_SETTINGS = "_qr_settings"
const QR_DATE = "_qr_date"
const REQUEST_PAYMENT = "_request_payment"
const TERMINAL_LOGIN_INFO = "_terminal_Login_info"

export function storeXAppVresion(token) {
    localStorage.setItem(X_POS_APP_VERSION, token);
}
export function storeSetTerminalLoginInfo(token) {
    localStorage.setItem(TERMINAL_LOGIN_INFO, token);
}
export function saveRequestPayment(paymentData) {
    localStorage.setItem(REQUEST_PAYMENT, paymentData);
}
export function storeSaveCreateQrDate(date) {
    localStorage.setItem(QR_DATE, date);
}
export function storeXInstallationId(token) {
    localStorage.setItem(X_INSTALLATION_ID, token);
}
export function setStoreQrSettings(params) {
    localStorage.setItem(QR_SETTINGS, params);
}
export function storeXPlatformVersion(token) {
    localStorage.setItem(X_PLATFORM_VERSION, token);
}
export function storeXPlatformType(token) {
    localStorage.setItem(X_PLATFORM_TYPE, token);
}
export function storeXDevice(token) {
    localStorage.setItem(X_DEVICE, token);
}
export function storeXNativeAppVersion(token) {
    localStorage.setItem(X_DEVICE_APP_VERSION, token);
}

export function storeTerminalError(err) {
    localStorage.setItem(TERMINAL_ERROR, err);
}
export function storeRemoveXAppVresion() {
    localStorage.removeItem(X_POS_APP_VERSION);
}
export function removeRequestPayment() {
    localStorage.removeItem(REQUEST_PAYMENT);
}
export function storeRemoveXInstallationId() {
    localStorage.removeItem(X_INSTALLATION_ID);
}
export function storeRemoveXPlatformVersion() {
    localStorage.removeItem(X_PLATFORM_VERSION);
}
export function storeRemoveCreateQrDate() {
    localStorage.removeItem(QR_DATE);
}
export function storeRemoveXPlatformType() {
    localStorage.removeItem(X_PLATFORM_TYPE);
}
export function storeRemoveXDevice() {
    localStorage.removeItem(X_DEVICE);
}
export function storeRemoveXNativeAppVersion() {
    localStorage.removeItem(X_DEVICE_APP_VERSION);
}
export function removeStoreQrSettings() {
    localStorage.removeItem(QR_SETTINGS);
}
export function getRequestPayment() {
    localStorage.getItem(TERMINAL_ERROR);
}
export function storeGetRequestPayment() {
    localStorage.getItem(REQUEST_PAYMENT);
}
export function storeGetXAppVresion() {
    localStorage.getItem(X_POS_APP_VERSION);
}
export function storeGetXInstallationId() {
    localStorage.getItem(X_INSTALLATION_ID);
}
export function storeGetTerminalLoginInfo() {
    localStorage.getItem(TERMINAL_LOGIN_INFO);
}
export function storeGetXPlatformVersion() {
    localStorage.getItem(X_PLATFORM_VERSION);
}
export function storeGetCreateQrDate() {
    localStorage.getItem(QR_DATE);
}
export function storeGetQrSettings() {
    localStorage.getItem(QR_SETTINGS);
}
export function storeGetXPlatformType() {
    localStorage.getItem(X_PLATFORM_TYPE);
}
export function storeGetXDevice() {
    localStorage.getItem(X_DEVICE);
}
export function storeGetXNativeAppVersion() {
    localStorage.getItem(X_DEVICE_APP_VERSION);
}

export function storeToken(token) {
    localStorage.setItem(SESSION_TOKEN, token);
}
export function setFetchRefreshToken(action) {
    localStorage.setItem(FETCH_TOKEN, action);
}
export function storeExpiredTime(time) {
    localStorage.setItem(SESSION_EXPIRED_TIME, time);
}

export function storeRefreshToken(token) {
    localStorage.setItem(REFRESH_TOKEN, token);
}

export function storeSellerInfo(sellerInfo) {
    localStorage.setItem(SELLER_INFO, sellerInfo);
}

export function storeShowLoginForm() {
    localStorage.setItem(SHOW_LOGIN_FORM, "1");
}

export function storeTerminalId(token) {
    localStorage.setItem(TERMINAL_ID, token);
}

export function storeDayState(state) {
    localStorage.setItem(DAY_STATE, state);
}

export function storeShiftId(shiftId) {
    localStorage.setItem(SHIFT_ID, shiftId);
}

export function storeUser(userData) {
    localStorage.setItem(USER_DATA, userData);
}

export function getToken() {
    return localStorage.getItem(SESSION_TOKEN);
}

export function getSellerInfo() {
    return localStorage.getItem(SELLER_INFO);
}

export function getShowLoginForm() {
    return localStorage.getItem(SHOW_LOGIN_FORM);
}

export function getLocalstorageToken() {
    return localStorage.getItem(SESSION_TOKEN);
}

export function getTerminalId() {
    return localStorage.getItem(TERMINAL_ID);
}

export function getShiftId() {
    return localStorage.getItem(SHIFT_ID);
}

export function getDayState() {
    return localStorage.getItem(DAY_STATE);
}

export function getExpiredTime() {
    return localStorage.getItem(SESSION_EXPIRED_TIME);
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
}

export function getUserData() {
    return localStorage.getItem(USER_DATA);
}

export function isTokenPresent() {
    return localStorage.getItem(SESSION_TOKEN) !== null;
}

export function removeToken() {
    localStorage.removeItem(SESSION_TOKEN);
}
export function storeRemoveTerminalError() {
    localStorage.removeItem(TERMINAL_ERROR);
}
export function removeShowLoginForm() {
    localStorage.removeItem(SHOW_LOGIN_FORM);
}

export function removeSellerInfo() {
    localStorage.removeItem(SELLER_INFO);
}

export function removeTerminalId() {
    localStorage.removeItem(TERMINAL_LOGIN_INFO);
    localStorage.removeItem(TERMINAL_ID);
}

export function removeShiftId() {
    localStorage.removeItem(SHIFT_ID);
}

export function removeTerminalLoginInfo() {
    localStorage.removeItem(TERMINAL_LOGIN_INFO);
}

export function removeDayState() {
    localStorage.removeItem(DAY_STATE);
}

export function removeExpiredTime() {
    localStorage.removeItem(SESSION_EXPIRED_TIME);
}

export function removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN);
}

export function removeUserData() {
    localStorage.removeItem(USER_DATA);
}

export function getMaintenanceModeStatus() {
    return localStorage.getItem(MAINTENANCE_MODE);
}