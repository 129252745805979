import React, { useState, useEffect } from "react";
import classNames from "classnames"
import { setShowBackButton, setShowNavbarPanel, changeLanguage } from "../../api/actionCreators/mainActionCreator"
import { getCurrentLanguage } from "../../api/selectors/languageSelector"
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { NAV } from "../../constants"
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from "react-redux";
import { CheckedIcon } from "../../svg-icons/icon-checked"
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from 'notistack';

import styles from "./languages.module.scss";

const Languages = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();

  const language = useSelector(getCurrentLanguage);
  const [selectedLang, setSelectedLang] = useState(language);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(setShowBackButton(true))
    dispatch(setShowNavbarPanel(false)) 
  }, [dispatch])

  useEffect(() => () => {
    dispatch(setShowBackButton(false))
    dispatch(setShowNavbarPanel(true))
  }, [dispatch]);

  const onChangeLanguage = () => {
    setIsLoading(true)
    setTimeout(() => {
      dispatch(changeLanguage(selectedLang));
      enqueueSnackbar(t("Languages_was_changed"))
      history.goBack()
    }, 2000);
  };

  const stateWithNoChanges = isLoading || language === selectedLang

  return (
    <AppMainLayout activeNav={NAV.LANGUAGES} backButtonUrl={"/app/profile"}>
      <div className={styles.languages_container}>
        <div>
          <div
            className={classNames({
              [`${styles.languages_line}`]: true,
              [`${styles.disabled_state}`]: isLoading,
            })}
            onClick={() => setSelectedLang("ro")}
          >
            <div className={styles.languages_container}>
              Română
            </div>
            <div>
              {selectedLang === "ro" && <CheckedIcon />}
            </div>
          </div>
          <div
            className={classNames({
              [`${styles.languages_line}`]: true,
              [`${styles.disabled_state}`]: isLoading,
            })}
            onClick={() => setSelectedLang("en")}
          >
            <div className={styles.languages_container}>
              English
            </div>
            <div>
              {selectedLang === "en" && <CheckedIcon />}
            </div>
          </div>
          <div
            className={classNames({
              [`${styles.languages_line}`]: true,
              [`${styles.disabled_state}`]: isLoading,
            })}
            onClick={() => setSelectedLang("ru")}
          >
            <div className={styles.languages_container}>
              Русский
            </div>
            <div>
              {selectedLang === "ru" && <CheckedIcon />}
            </div>
          </div>
        </div>


        <div className={styles.btn_container} onClick={() => stateWithNoChanges ? {} : onChangeLanguage()}>
          <div
            className={classNames({
              [`${styles.day_action_button}`]: true,
              [`${styles.disabled_button}`]: isLoading,
              [`${styles.button_no_changes}`]: language === selectedLang,
            })}
          >
            {!isLoading && t("Apply")}
            {isLoading && <CircularProgress className="circular-progress" size={22} />}
          </div>
        </div>


      </div>
    </AppMainLayout>
  );
};
export default Languages;
