import React, { useState, useEffect } from "react";
import { ISignInPageContent } from "../../interfaces";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api/services/Api"
import {
  authEnd,
  setTokenTerminalId,
  setExpiredTime,
  setTokenInHeaders,
  setTokenExpired,
  setIsAuthFetching
} from "../../api/actionCreators/authActionCreator";
import { changeLanguage } from "../../api/actionCreators/mainActionCreator"
import classNames from "classnames"
import {
  getIsAuthFetching,
} from "../../api/selectors/authSelector";
// import {
//   getToken,
//   getAccessToken,
// } from "../../api/selectors/tokenSelector";
import CircularProgress from "@mui/material/CircularProgress";
import { Redirect } from "react-router-dom";
import {
  storeToken,
  storeExpiredTime,
  storeRefreshToken,
  storeTerminalId,
  removeShowLoginForm,
  storeSellerInfo,
  storeXAppVresion,
  storeXInstallationId,
  storeXPlatformVersion,
  storeXPlatformType,
  storeXDevice,
  storeXNativeAppVersion,
  removeTerminalId,
  storeTerminalError,
  setStoreQrSettings,
  storeSetTerminalLoginInfo,
} from "../../api/services/Auth";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { onlyNumbersRegex, capitalizeFirstLetter, miaPosEvents, getBrowserName } from "../../constants";
import { BackButtonIcon } from "../../svg-icons/icon-back-button"
import StepOtp from "./step-otp"
import LoginPageLayout from "../../infrastructure/layouts/LoginPageLayout"
import { ErrorFillIcon } from "../../svg-icons/icon-error-fill"
// import { createServer } from 'miragejs';
// import authHandler from '../../api/miragejs/handlers/authHandler';
import { v4 as uuidv4 } from 'uuid';
import MiaPos from "../../_bridge/init_miapos"
import { enqueueSnackbar } from 'notistack';
import { LogoSuccessIcon } from "../../svg-icons/icon-logo-success"
import { SuccessIcon } from "../../svg-icons/icon-success"

import styles from "./signin-page.module.scss";

// createServer({
//   routes() {
//     const mockBaseUrl = 'http://localhost:3003/';
//     authHandler(this, mockBaseUrl)
//     this.passthrough()
//   }
// })

const SignInPageContent = (props: ISignInPageContent) => {
  const dispatch = useDispatch();
  const { GET_DEVICE_INFO, GET_EXTERNAL_PAYMENT_REQUEST } = miaPosEvents

  // const emptyDeviceInfo = {
  //   installationId: "",
  //   deviceName: "",
  //   platformType: "",
  //   platformVersion: "",
  //   applicationVersion: "",
  // }

  const isAuthFetching = useSelector(getIsAuthFetching);
  // const token = useSelector(getToken);
  const token = localStorage.getItem('_session_token');
  const terminalId = localStorage.getItem('_terminal_id');
  // const accessToken = useSelector(getAccessToken);
  const { t } = useTranslation();
  const emptyFieldsValues = { idno: "", terminalId: "", login: "", password: "" }
  const [fieldsValues, setFieldsValues] = useState(emptyFieldsValues);
  const [terminalAccepted, setTerminalAccepted] = useState(false);
  const [gettingTerminalInfo, setGettingTerminalInfo] = useState(false);
  //const [deviceInfo, setDeviceInfo] = useState(emptyDeviceInfo);
  const [showOtpContent, setShowOtpContent] = useState(false);
  const [responseData, setResponseData] = useState({ maskedPhoneNumber: "", terminalActivationId: "", waitTimeToResend: null });
  const [error, setError] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [canRedirect, setCanRedirect] = useState(false);
  const [fetchUserInfo, setFetchUserInfo] = useState(false);
  const [showTerminalSuccessActivated, setShowTerminalSuccessActivated] = useState(false);
  const [fetchQrSettings, setFetchQrSettings] = useState(false);
  const [fireToGetTerminalInfo, onFireToGetTerminalInfo] = useState(false);
  const [activeTerminalInfo, setActiveTerminalInfo] = useState({ terminalId: "", idno: "", placeName: "", placeAddres: "" });
  const [getTerminalInfo, setGetTerminalInfo] = useState(false);

  const showLoginForm = localStorage.getItem('_show_login_form');
  const storageTerminalId = localStorage.getItem('_terminal_id');
  const selectedLang = localStorage.getItem('_preferred_language');
  const terminalError = localStorage.getItem('_terminal_error');

  useEffect(() => {
    const browser = getBrowserName()
    const UUID = uuidv4();
    const appVersion = 1;
    const langByDefault = "ro"
    storeXAppVresion(appVersion)
    storeXInstallationId(UUID) //generae UUID
    storeXPlatformVersion("")
    storeXPlatformType("web")
    storeXDevice(browser) // browser by default

    MiaPos.send(GET_DEVICE_INFO).then(res => {
      console.log("res +++++++++", res)
      if (res) {
        // const data = {
        //   installationId: res.installationId,
        //   deviceName: res.deviceName,
        //   platformType: res.platformType,
        //   platformVersion: res.platformVersion,
        //   applicationVersion: res.applicationVersion,
        // }
        //setDeviceInfo(data)

        storeXAppVresion(appVersion)
        storeXInstallationId(res.installationId)
        storeXPlatformVersion(res.platformVersion)
        storeXPlatformType(res.platformType)
        storeXDevice(res.deviceName)
        storeXNativeAppVersion(res.applicationVersion)
        !selectedLang && dispatch(changeLanguage(res.locale))
      } else {
        enqueueSnackbar(t('Error_server'), { variant: 'error' })
      }

    }).catch((errorInfo) => {
      storeXAppVresion(appVersion)
      storeXInstallationId(UUID)
      storeXPlatformVersion("")
      storeXPlatformType("web")
      storeXDevice(browser)
      !selectedLang && dispatch(changeLanguage(langByDefault))
      console.log("Error when requesting user data from MiaPos", errorInfo)
      //enqueueSnackbar(t('Error_server'))
    })


    // MiaPos.send(GET_EXTERNAL_PAYMENT_REQUEST).then(res => {
    //   if (!!res) {
    //     saveRequestPayment(JSON.stringify(res))
    //   }
    // })

    // const requestParam = { // for test
    //   requestId: "kjsdhgkfdjhgkdfg", amount: 951, currency: "mdl"
    // }
    // saveRequestPayment(JSON.stringify(requestParam))





    if (showLoginForm || storageTerminalId) {
      //
      // storeTerminalError === "terminal_is_not_active" && removeTerminalId()
      // storeTerminalError === "session_is_not_active" && setTerminalAccepted(true)
      //removeShowLoginForm()
      console.log("terminalError", terminalError)
      if (terminalError === "terminal_is_not_active") {
        removeTerminalId()
        storeTerminalError("")
        removeShowLoginForm()
      } else if (terminalError === "session_is_not_active") {
        setTerminalAccepted(true)
        storeTerminalError("")
      } else {
        storageTerminalId && setTerminalAccepted(true)
      }

    }

  }, [GET_DEVICE_INFO, showLoginForm, storageTerminalId, terminalError]);

  useEffect(() => {
    console.log("!!!!!!!!!!!responseData", responseData)
    // if (terminalId?.length > 0) {
    if (fireToGetTerminalInfo) {
      setGettingTerminalInfo(true)

      // setTimeout(() => {
      //   setGettingTerminalInfo(false)
      //   setActiveTerminalInfo({
      //     terminalId: "56145",
      //     idno: "565156151165",
      //     placeName: "”ULTRA DISTRIBUTION” S.R.L",
      //     placeAddres: "str. Ion Creanga, 6A."
      //   })
      // }, 2000);

      axios
        .get(`/pos/api/v1/terminal-activation/${responseData.terminalActivationId}`)
        .then(res => {
        
          const { status } = res
          if (status === 200 || status === 201 || status === 204) {
            console.log("res", res.data.result)
            if (res.data.result === "success") {
              const obj = {
                terminalId: res.data.terminalId,
                idno: res.data.merchantIdno,
                placeName: res.data.merchantName,
                placeAddres: res.data.storeAddress
              }

              storeSetTerminalLoginInfo(JSON.stringify(obj))
            } else if (res.data.result === "terminal_is_not_active") {
              enqueueSnackbar(t("Terminal_is_not_active"), { variant: "error" });
            } else if (res.data.result === "activation_not_found") {
              enqueueSnackbar(t("Activation_not_found"), { variant: "error" });
            }
          } else if (status === 400) {
            enqueueSnackbar(t("Error_400"), { variant: "error" });
          } else if (status === 401) {
            enqueueSnackbar(t("Error_401"), { variant: "error" });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else if (status === 404) {
            enqueueSnackbar(t("Error_404"), { variant: "error" });
          } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
          }




          console.log("res+++++", res)
        }).finally(() => {
          setGettingTerminalInfo(false);
          onFireToGetTerminalInfo(false)
        })
    }
  }, [fireToGetTerminalInfo])

  useEffect(() => {
    token && setCanRedirect(true);
  }, []);

  useEffect(() => {
    canRedirect && setRedirect(true);
  }, [canRedirect]);
  useEffect(() => {
    if (fetchUserInfo) {
      axios
        .get(`/pos/api/v1/seller/info`)
        .then(res => {

          dispatch(setIsAuthFetching(false))
          storeSellerInfo(JSON.stringify(res.data))
          // setCanRedirect(true)
          setFetchQrSettings(true)
          // setRedirect(true)
        })
    }

  }, [fetchUserInfo, dispatch]);

  useEffect(() => {
    fetchQrSettings && axios
      .get(`/pos/api/v1/settings`)
      .then(res => {
        const { status } = res
        if (status === 200 || status === 201 || status === 204) {
          console.log("res", res.data.result)
          if (res.data.result === "success") {
            const storeQr = {
              currentType: res.data.qrSettings.qrType,
              currentAmountType: res.data.qrSettings.qrAmountType,
            }
            setStoreQrSettings(JSON.stringify(storeQr))
            setCanRedirect(true)
          }
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
        } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }
      }).catch(err => {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      })
  }, [fetchQrSettings])

  const onLogin = () => {
    setError("")
    dispatch(setIsAuthFetching(true))
    const terminalId = localStorage.getItem('_terminal_id');

    const params = {
      terminalActivationId: terminalId,
      username: fieldsValues.login,
      password: fieldsValues.password

    }
    axios
      .post(`/pos/api/v1/seller/login`, { ...params })
      .then(res => {

        if (res.data.result === "success") {

          // const tokenData = {
          //   accessToken: res.data.authTokens.accessToken,
          //   accessTokenExpiresIn: res.data.authTokens.accessTokenExpiresIn,
          //   refreshToken: res.data.authTokens.refreshToken,
          //   terminalActivationId: responseData.terminalActivationId
          // };

          storeToken(res.data.authTokens.accessToken);
          storeRefreshToken(res.data.authTokens.refreshToken);

          const updatedDate = moment().add(res.data.authTokens.accessTokenExpiresIn, "seconds");
          // const updatedDate = moment().add(30, "seconds");
          storeExpiredTime(updatedDate);


          dispatch(setExpiredTime(updatedDate.toDate()));
          dispatch(authEnd());
          dispatch(setTokenInHeaders(true));
          dispatch(setTokenExpired(false));
          //
          setFetchUserInfo(true)
          dispatch(setIsAuthFetching(false))

        } else {

          const locale = res.data.result === "not_found" ? "not_found_sign_in_page" : res.data.result
          enqueueSnackbar(t(capitalizeFirstLetter(locale)), { variant: 'error' })
          dispatch(setIsAuthFetching(false))
          if (res.data.result === "terminal_is_not_active") {
            removeTerminalId()
            hideOtpFormAndShowTerminal()

          }
        }
      })
      .catch(() => {
        dispatch(setIsAuthFetching(false))
        enqueueSnackbar(t("Error_server"), { variant: 'error' })
      });
  }

  const setOnchangeInput = (value, state) => {
    setError("")
    const values = {
      ...fieldsValues,
      [state]: value
    }
    const isValid = onlyNumbersRegex(value)

    if (state === "idno") {
      if (isValid || value === "") {
        setFieldsValues(values)
      }
    } else {
      setFieldsValues(values)
    }
  }

  const onShowLoginForm = () => {
    setTerminalAccepted(true)
    setShowOtpContent(false)
    setFieldsValues(emptyFieldsValues)
  }

  const hideOtpFormAndShowTerminal = () => {
    setTerminalAccepted(false)
    setShowOtpContent(false)
  }

  const terminalToShowOtp = () => {
    // setShowLoaderInButton(true)
    dispatch(setIsAuthFetching(true))
    const params = {
      terminalId: fieldsValues.terminalId,
      merchantIdno: fieldsValues.idno
    }

    axios
      .post(`/pos/api/v1/terminal-activation/init`, { ...params })
      .then(res => {

        if (res.data.result === "success") {
          const { maskedPhoneNumber, waitTimeToResend, terminalActivationId } = res.data
          setResponseData({
            terminalActivationId,
            maskedPhoneNumber,
            waitTimeToResend
          })
          dispatch(setTokenTerminalId(terminalActivationId));

          setShowOtpContent(true)
          // storeTerminalId(terminalActivationId);
          dispatch(setIsAuthFetching(false))
          // enqueueSnackbar(t("OK"), { variant: 'success' })
        } else {
          // setError(capitalizeFirstLetter(res.data.result))
          const locale = res.data.result === "not_found" ? "not_found_sign_in_page" : res.data.result
          enqueueSnackbar(t(capitalizeFirstLetter(locale)), { variant: 'error' })
          dispatch(setIsAuthFetching(false))
        }
      })
      .catch(err => {
        dispatch(setIsAuthFetching(false))
        enqueueSnackbar(t("Error_server"), { variant: 'error' })
        // setError(t("Error_server"))
      });

  }

  const errorContent = () => {
    return (
      <div className={styles.error_content}>
        <ErrorFillIcon />
        {/* <label>{t('OTP_Error_message')}</label> */}
        <label>{t(error)}</label>
      </div>
    )
  }

  const isValid = terminalAccepted ? !fieldsValues.password || !fieldsValues.login : !fieldsValues.idno || !fieldsValues.terminalId

  const renderTerminalContainer = () => {
    const firstTextInput = terminalAccepted ? fieldsValues.login : fieldsValues.idno
    const secondInput = terminalAccepted ? fieldsValues.password : fieldsValues.terminalId

    const stateValueFirst = terminalAccepted ? "login" : "idno"
    const stateValueSecond = terminalAccepted ? "password" : "terminalId"
    return (
      <>


        <TextField
          id="outlined-basic-login-idno"
          className={`${styles.sign_in_input} ${styles.mb_24}`}
          variant="outlined"
          value={firstTextInput}
          onChange={(e) => setOnchangeInput(e.target.value, stateValueFirst)}
          fullWidth
          label={terminalAccepted ? t("Login") : "IDNO"}
          inputProps={{
            tabIndex: 1,
            type: terminalAccepted ? "text" : "number",
            inputMode: terminalAccepted ? "text" : "numeric",
          }}
        />
        <TextField
          id="outlined-basic-password-terminal-id"
          className={`${styles.sign_in_input} ${styles.mb_24}`}
          variant="outlined"
          fullWidth
          onChange={(e) => setOnchangeInput(e.target.value, stateValueSecond)}
          value={secondInput}
          label={terminalAccepted ? t("Password") : t("Terminal_id")}
          type={terminalAccepted ? "password" : "text"}
          inputProps={{
            tabIndex: 1
          }}
        />


        <div
          className={styles.sign_in_btn_container}
          onClick={() => (isAuthFetching || isValid) ? {} : terminalAccepted ? onLogin() : terminalToShowOtp()}
        >
          <div
            className={classNames({
              [`${styles.sign_in_action_button}`]: true,
              [`${styles.sign_in_disabled_button}`]: isValid,
              [`${styles.sign_in_is_fetching_button}`]: isAuthFetching,
            })}
          >
            {!isAuthFetching ? terminalAccepted ? t("Enter") : t("Next") : ""}
            {isAuthFetching && <CircularProgress className="circular-progress" size={26} />}
          </div>
        </div>



        {error && errorContent()}
      </>
    )
  }

  const renderTerminalSuccessActivatedModal = () => {
    return (
      <div className={styles.succuss_view_container}>
        <div className={styles.succuss_view_content}>
          <div className={styles.succuss_view_content_success_info}>
            <div className={styles.succuss_view_content_logo}>
              <LogoSuccessIcon />
            </div>
            <div className={styles.succuss_view_content_info}>
              <SuccessIcon />
              <div className={styles.success_title}>
                {t('Pos_successfully_activated')}
              </div>
            </div>
          </div>
          <div className={styles.btn_container} onClick={() => { setShowTerminalSuccessActivated(false); onFireToGetTerminalInfo(true) }}>
            <div
              className={classNames({
                [`${styles.cancel_action_button}`]: true,
              })}
            >
              {t("Continue")}
            </div>
          </div>
        </div>
      </div>
    )
  }


  const pageTitle = !showOtpContent && !terminalAccepted ? t('Enter_data_about_your_terminal') :
    showOtpContent && !terminalAccepted ? t("OTP_confirmation") : t("Sign_in")
  return (
    <LoginPageLayout {...{ pageTitle, activeTerminalInfo, gettingTerminalInfo }}>
      {redirect && <Redirect to={"/app/terminal"} />}
      {showTerminalSuccessActivated && renderTerminalSuccessActivatedModal()}
      {showOtpContent &&
        <BackButtonIcon
          onClick={() => setShowOtpContent(false)}
          className={classNames({
            [`${styles.back_button}`]: true,
          })}
        />}
      <div className={styles.main_container}>
        {!showOtpContent ? renderTerminalContainer() :
          <StepOtp
            showLoginForm={onShowLoginForm}
            hideOtpFormAndShowTerminal={hideOtpFormAndShowTerminal}
            setShowTerminalSuccessActivated={() => setShowTerminalSuccessActivated(true)}
            setGetTerminalInfo={setGetTerminalInfo}
            {...responseData}
          />
        }
      </div>
    </LoginPageLayout>

  );
};
export default SignInPageContent;
