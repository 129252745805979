
import React from 'react';

export const LogoTextIcon = () => {
    return (
        <svg width="78" height="19" viewBox="0 0 78 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.4643 8.16206C44.4643 7.67306 44.3257 7.30086 44.0516 7.04545C43.7759 6.79005 43.3632 6.66235 42.8119 6.66235C42.0364 6.66235 41.373 6.89128 40.8232 7.35069C40.2719 7.8101 39.9153 8.43771 39.7518 9.23351V9.20236L37.8845 17.925H32.2236L34.1827 8.7741C34.2232 8.4891 34.2435 8.28353 34.2435 8.16206C34.2435 7.6933 34.1002 7.32577 33.8152 7.06103C33.5302 6.79628 33.1222 6.66391 32.5911 6.66391C31.8156 6.66391 31.1522 6.88816 30.6024 7.33667C30.0511 7.78519 29.6836 8.41902 29.4998 9.23351L27.6637 17.925H22.0028L25.3075 2.47H30.5713L29.8674 5.71393C30.4794 4.59265 31.3063 3.70497 32.3466 3.05088C33.3869 2.39836 34.5191 2.07132 35.7432 2.07132C36.9673 2.07132 37.9406 2.37656 38.727 2.98859C39.5119 3.60062 39.9869 4.43691 40.1504 5.49746C40.7625 4.45716 41.5894 3.62554 42.6297 3.00261C43.67 2.37967 44.782 2.06976 45.9655 2.06976C47.3531 2.06976 48.4448 2.45754 49.2391 3.23309C50.0349 4.00865 50.432 5.04895 50.432 6.35555C50.432 6.74333 50.3806 7.21209 50.2794 7.76338L48.1069 17.9234H42.446L44.4051 8.77254C44.4456 8.62926 44.4658 8.42525 44.4658 8.1605L44.4643 8.16206Z" fill="white" />
            <path d="M54.562 2.47003H60.2229L56.9478 17.925H51.2869L54.562 2.47003Z" fill="white" />
            <path d="M60.6356 13.0599C60.8396 12.0803 61.3395 11.2752 62.1353 10.6429C62.9311 10.0106 63.9823 9.60258 65.2874 9.41881L71.8671 8.53113L72.0198 7.85836C72.0602 7.61386 72.0805 7.43009 72.0805 7.30707C72.0805 6.8165 71.9076 6.43963 71.5603 6.17488C71.213 5.91013 70.7038 5.7762 70.031 5.7762C69.3583 5.7762 68.7151 5.9553 68.1638 6.31193C67.6125 6.66856 67.2045 7.22608 66.9397 7.97983H62.1042C62.492 6.10324 63.4248 4.65492 64.9043 3.63487C66.3838 2.61481 68.2354 2.104 70.4593 2.104C72.6832 2.104 74.5442 2.5167 75.7994 3.34364C77.0546 4.17059 77.6822 5.33859 77.6822 6.84765C77.6822 7.21518 77.6308 7.63411 77.5296 8.10286L75.449 17.9265H70.2459L70.8268 15.1716C70.3378 16.1107 69.5825 16.866 68.5625 17.436C67.5424 18.0075 66.3791 18.2925 65.074 18.2925C63.646 18.2925 62.5231 17.925 61.7071 17.1915C60.891 16.4564 60.483 15.4987 60.483 14.3151C60.483 13.9071 60.5235 13.5395 60.606 13.214L60.6356 13.0614V13.0599ZM66.0832 13.6408C66.0832 14.069 66.2265 14.4054 66.5115 14.6499C66.7964 14.8944 67.1951 15.0174 67.7044 15.0174C68.5204 15.0174 69.2337 14.7932 69.8457 14.3447C70.4577 13.8962 70.8455 13.3153 71.009 12.6005L71.3143 11.1926L68.1015 11.6816C67.5097 11.7844 67.0503 11.9682 66.7248 12.2329C66.3978 12.4977 66.2047 12.7842 66.1439 13.0895L66.1128 13.2421C66.0925 13.3246 66.0816 13.457 66.0816 13.6408H66.0832Z" fill="white" />
            <path d="M6.32663 8.48596C9.10803 9.06217 11.388 7.85212 11.8723 5.51612C12.3566 3.18011 10.7557 1.10886 7.91823 0.521739C5.19289 -0.0420165 3.03598 1.13533 2.54074 3.52584C2.05641 5.86185 3.54678 7.9113 6.32819 8.48751L6.32663 8.48596Z" fill="#A88FF5" />
            <path d="M8.42124 14.8104C8.99746 12.029 7.78741 9.74903 5.4514 9.2647C3.1154 8.78037 1.04414 10.3813 0.457027 13.2188C-0.106729 15.9441 1.07062 18.101 3.46113 18.5963C5.79713 19.0806 7.84659 17.5902 8.4228 14.8088L8.42124 14.8104Z" fill="#669999" />
            <path d="M15.0898 10.2085C12.3084 9.63226 10.0284 10.8423 9.54409 13.1783C9.05976 15.5143 10.6607 17.5856 13.4982 18.1727C16.2235 18.7364 18.3804 17.5591 18.8757 15.1686C19.36 12.8326 17.8696 10.7831 15.0882 10.2069L15.0898 10.2085Z" fill="#B8753B" />
            <path d="M12.9858 3.87628C12.4096 6.65768 13.6196 8.93762 15.9556 9.42196C18.2916 9.90629 20.3629 8.30534 20.95 5.46788C21.5138 2.74254 20.3364 0.585629 17.9459 0.0903962C15.6099 -0.393935 13.5605 1.09643 12.9842 3.87784L12.9858 3.87628Z" fill="#FF7859" />
        </svg>
    );
};