import React from "react";
import Terminal from "./pages/Terminal";
import Transactions from "./pages/Transactions"
import Profile from "./pages/Profile"
// import LoginPage from "./pages/LoginPage"
import SignInPage from "./pages/SignInPage"
import Languages from "./pages/Languages"
import Support from "./pages/Support"
import QrSettings from "./pages/QrSettings" 
import { Route, Switch, Redirect } from "react-router-dom";
import TransactionsDetails from "./pages/TransactionsDetails";

import "./App.scss";


const App = () => {


  return (
    <Switch>
      <Route path="/app/" exact component={SignInPage} />
      <Route path="/app/terminal" exact component={Terminal} />
      
      <Route path="/app/transactions" exact component={Transactions} />
      <Route path="/app/transactions/:trxId" exact component={TransactionsDetails} />

      <Route path="/app/profile" exact component={Profile} />
      <Route path="/app/languages" exact component={Languages} />
      <Route path="/app/support" exact component={Support} />
      <Route path="/app/qr-settings" exact component={QrSettings} />
      <Redirect to="/app" />
    </Switch>
  );
};

export default App;
