import React, { useState, useEffect } from "react";
import { IProfile } from "../../interfaces";
import classNames from "classnames"
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { NAV, selleInfoDefault } from "../../constants"
import { DayStatusIcon } from "../../svg-icons/icon-day-status"
import { UserIcon } from "../../svg-icons/icon-user"
import { LanguageIcon } from "../../svg-icons/icon-languages"
import { NextIcon } from "../../svg-icons/icon-next"
import { QrCodeMenuIcon } from "../../svg-icons/icon-qr-code-menu"
import { UserSupportIcon } from "../../svg-icons/icon-user-support"
// import { LogoutIcon } from "../../svg-icons/icon-logout"
// import { IsNewIcon } from "../../svg-icons/icon-is-new"
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setShowModalOpenCloseDay, setShowModalLogout } from "../../api/actionCreators/mainActionCreator"
import styles from "./profile.module.scss";
import {
  getSellerInfo
} from "../../api/services/Auth";
import { useTranslation } from "react-i18next";
import { PoweredByIcon } from "../../svg-icons/icon-powered-by";

const Profile = (props: IProfile) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDayOpen = localStorage.getItem('_day_state') === "opened";
  const [profileInfo, setProfileInfo] = useState(selleInfoDefault)

  useEffect(() => {
    setProfileInfo(JSON.parse(getSellerInfo()))
  }, [])

  const renderDayStatus = () => {
    return (
      <div className={styles.days_status_container}>
        <DayStatusIcon />
        {t("Day_is_closed")}
      </div>
    )
  }

  const renderUserInfo = () => {
    return (
      <div className={styles.user_container}>
        <div className={styles.user_name_container}>
          <div className={styles.user_icon}>
            <UserIcon />
          </div>
          <div>
            <div className={styles.user_name}>{profileInfo?.sellerInfo?.fullName}</div>
            <div className={styles.user_id}>{t('Seller_id')}: {profileInfo?.sellerInfo?.id}</div>
          </div>
        </div>


        <div className={styles.open_close_day_profile}>
          {renderDayActionButton()}
        </div>

      </div>
    )
  }

  const renderUserInfoLines = () => {
    return (
      <div className={styles.user_lines_container}>


        <span className={styles.seller_description}>
          <div>
            {t('Terminal')} #{profileInfo?.terminalInfo?.terminalId}
          </div>
          <div>
            {profileInfo?.placeInfo?.name}
          </div>
          <div>
            {profileInfo?.placeInfo?.address}
          </div>

        </span>


        <Link
          className={classNames({
            [`${styles.user_line}`]: true,
            [`${styles.bold}`]: true,
          })}
          to="/app/qr-settings"
        >
          <div className={styles.user_icon_container}>
            <QrCodeMenuIcon />
          </div>
          <span className={styles.j_content}>
            {t('QR_code_settings')}
            <NextIcon />
          </span>

        </Link>
        <Link
          className={classNames({
            [`${styles.user_line}`]: true,
            [`${styles.bold}`]: true,
          })}
          to="/app/languages"
        >
          <div className={styles.user_icon_container}>
            <LanguageIcon />
          </div>
          <span className={styles.j_content}>
            {t('Switch_language')}
            <NextIcon />
          </span>

        </Link>

        <Link
          className={classNames({
            [`${styles.user_line}`]: true,
            [`${styles.bold}`]: true,
          })}
          to="/app/support"
        >

          <div className={styles.user_icon_container}>
            <UserSupportIcon />
          </div>
          <span className={styles.j_content}>
            {t("Support")}
            <NextIcon />
          </span>

        </Link>

        {/* <div
          className={classNames({
            [`${styles.user_line}`]: true,
            [`${styles.logout_line}`]: true,
            [`${styles.bold}`]: true,
          })}
          onClick={() => dispatch(setShowModalLogout(true))}
        >
          <div className={styles.user_icon_container}>
            <LogoutIcon />
          </div>
          <span className={styles.j_content}>
            {t("Exit")}
            <NextIcon />
          </span>

        </div> */}
      </div>
    )
  }

  const renderDayActionButton = () => {
    return (
      <div className={styles.btn_container} onClick={() => dispatch(setShowModalOpenCloseDay(true))}>
        <div
          className={classNames({
            [`${styles.day_action_button}`]: true,
            [`${styles.is_open}`]: isDayOpen,
          })}
        >
          {isDayOpen ? t("Close_day") : t("Open_day")}
        </div>
      </div>
    )
  }

  return (
    <AppMainLayout activeNav={NAV.PROFILE} showExitButton>
      <div className={styles.wrapper}>
        <div>
          {!isDayOpen && renderDayStatus()}
          {renderUserInfo()}
          {renderUserInfoLines()}
        </div>
        <PoweredByIcon className={styles.powered_by}/>
      </div>
    </AppMainLayout>
  );
};
export default Profile;
