import React, { useState, useEffect, useCallback } from "react";
import { ITransactionsDetails } from "../../interfaces";
import classNames from "classnames"
import { setShowBackButton, setShowNavbarPanel, setShowRevertContent } from "../../api/actionCreators/mainActionCreator"
import { getShowRevertContent } from "../../api/selectors/mainSelector"
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { NAV, TYPES, capitalizeFirstLetter, amountFormatter } from "../../constants"
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { TransactionCompleteIcon } from "../../svg-icons/icon-transaction-complete"
import { TransactionReturnedIcon } from "../../svg-icons/icon-transaction-returned"
import axios, { apiRequest } from "../../api/services/Api"
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { GenerateIcon } from "../../svg-icons/icon-generate";
import { Switch, TextField } from "@mui/material";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import {
  removeToken,
  removeRefreshToken,
  removeDayState,
  removeExpiredTime,
  storeShowLoginForm,
  removeSellerInfo,
  removeShiftId,
  removeTerminalLoginInfo,
  removeTerminalId,
} from "../../api/services/Auth";

import styles from "./transactions-details.module.scss";

const TransactionsDetails = (props: ITransactionsDetails) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { trxId } = useParams();
  const emptyObj = {
    id: null,
    amount: null,
    ccy: null,
    date: null,
    type: null,
    rrn: null,
    approvalCode: null,
    mсс: null,
    swiftMessageType: null,
    swiftMessageId: null,
    swiftPayerBank: null,
  }

  const revertSummDataInfo = {
    revertReason: "",
    revertReasonDescription: "",
    isPartial: false,
    partialSumm: "",
  };


  const [trxInfo, setTrxInfo] = useState(emptyObj)
  // const [showRevertContent, setShowRevertContent] = useState(false)
  const [resonList, setReasonList] = useState([]);
  const [revertSummData, setRevertSummData] = useState({ ...revertSummDataInfo });
  const [isReverting, setIsReverting] = useState(false);
  const [trxRequestId, setTrxRequestId] = useState(null);
  const [checkTrxStatus, setCheckTrxStatus] = useState(5);
  const [checkCounter, setCheckCounter] = useState(0);
  const [redirectTrxId, setRedirectTrxId] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const showRevertContent = useSelector(getShowRevertContent);

  useEffect(() => {
    if (trxRequestId) {
      const checkTrx = setInterval(() => {
        setCheckTrxStatus((prevProgress) => prevProgress - 1);
      }, 1000);

      if (checkTrxStatus === 0) {
        // setCheckTrxStatus(5)
        // setCheckCounter(checkCounter + 1)
        axios
          .get(`/pos/api/v1/tx/return/${trxRequestId}/status`)
          .then((res) => {
            setCheckCounter(checkCounter + 1);
            if (res.data.status === "confirmed") {
              setRedirectTrxId(res.data.txReturnId);
              enqueueSnackbar(t("Trx_revert_confirmed"), {
                variant: "success",
              });

              setTimeout(() => {
                setRedirect(true);
                resetDataRevertModal();
              }, 500);
            } else if (res.data.status === "processing") {
              setCheckTrxStatus(5);
            } else if (res.data.status === "declined") {
              enqueueSnackbar(t("Trx_revert_declined"), { variant: "error" });
              resetDataRevertModal();
            } else if (res.data.status === "error") {
              enqueueSnackbar(t("Trx_revert_error"), { variant: "error" });
              resetDataRevertModal();
            }
          }).catch(() => {
            resetDataRevertModal();
            enqueueSnackbar(t("Error_500"), { variant: "error" });
          });
      }

      return () => {
        clearInterval(checkTrx);
      };
    }
  }, [trxRequestId, checkTrxStatus]);

  const resetDataRevertModal = () => {
    setRevertSummData({ ...revertSummDataInfo });
    setTrxRequestId(null);
    // setRedirectTrxId(null);
    setCheckTrxStatus(5);
    setCheckCounter(0);
    dispatch(setShowRevertContent(false))
  };

  useEffect(() => {
    if (checkCounter === 11) {
      resetDataRevertModal();
      enqueueSnackbar(t("Trx_check_status_in_table"), { variant: "error" });
      setRedirect(true)
    }
  }, [checkCounter]);

  useEffect(() => {
    const getReasonsList = async () => {
      try {
        const { status, response } = await apiRequest(
          "get",
          `/pos/api/v1/tx/return/reason`,
          null
        );

        if (status === 200 || status === 201) {
          const { data } = response;
          setReasonList(data.reasonList);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }
      } catch (error) {
        console.error("Failed to fetch transaction details:", error);
      }
    };
    showRevertContent && resonList.length === 0 && getReasonsList();
  }, [showRevertContent]);


  useEffect(() => {

    axios
      .get(`/pos/api/v1/tx/${trxId}`)
      .then(res => {
        const { result, txInfo } = res.data

        if (result === "success") {
          setTrxInfo(txInfo)
        } else {
          enqueueSnackbar(t(capitalizeFirstLetter(result)))
        }
      })

  }, [trxId, t])

  useEffect(() => {
    dispatch(setShowBackButton(true))
    dispatch(setShowNavbarPanel(false))
  }, [dispatch])

  useEffect(() => () => {
    dispatch(setShowBackButton(false))
    dispatch(setShowNavbarPanel(true))
  }, [dispatch]);


  const handleInputChange = (e) => {
    let { value } = e.target;
    const isValidInput = /^[0-9]*[.,]?[0-9]*$/.test(value);
    if (isValidInput) {
      value = value.replace(/,/g, ".");
      setRevertSummData((prevData) => ({
        ...prevData,
        partialSumm: value,
      }));
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("Text");
    if (!/^[0-9]*[.,]?[0-9]*$/.test(pasteData)) {
      e.preventDefault();
    }
  };

  const transactionDetailsContent = () => {
    return (
      <div className={styles.transaction_details_container}>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Transaction_id")}:
          </div>
          <div className={styles.bold}>
            {/* {t("Terminal")}  */}
            {trxInfo.id}
          </div>

        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t('Amount')}:
          </div>
          <div className={styles.bold}>
            {amountFormatter(trxInfo.amount)} <span className={styles.uppercase}>{trxInfo.ccy}</span>
          </div>
        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Status")}:
          </div>
          <div
            className={classNames({
              [`${styles.bold}`]: true,
              [`${styles.green}`]: trxInfo.type === "paid",
              [`${styles.gray}`]: trxInfo.type !== "paid",
            })}
          >
            {trxInfo.type === "paid" ? t("Paid") : trxInfo.type === "revert" ? t("Revert") : t("Partial_revert")}
            {trxInfo.type === "paid" ?
              <TransactionCompleteIcon /> :
              trxInfo.type && <TransactionReturnedIcon />}
          </div>
        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Transaction_swift_type")}:
          </div>
          <div className={styles.bold}>
            {trxInfo.swiftMessageType}
          </div>

        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Transaction_swift_id")}:
          </div>
          <div className={styles.bold}>
            {trxInfo.swiftMessageId}
          </div>

        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Transaction_swift_bank")}:
          </div>
          <div className={styles.bold}>
            {trxInfo.swiftPayerBank}
          </div>

        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Date")}:
          </div>
          <div className={styles.bold}>
            {moment(trxInfo.date).format("HH:mm, DD.MM.YYYY")}
          </div>

        </div>

        <div className={styles.transaction_revert}>
          {trxInfo.type === "paid" && (

            <div
              className={classNames({
                [`${styles.cancel_action_button}`]: true,
                // [`${styles.is_disabled}`]: isReverting || disabledButton
              }
              )}
              onClick={() => dispatch(setShowRevertContent(true))}
            >
              <GenerateIcon />{t("Revert_transaction")}
            </div>

            // <Button
            //   variant="text"
            //   // icon={<GenerateIcon />}
            //   // label={t("Revert_transaction")}
            //   onClick={() => setShowRevertContent(true)}
            //   className={styles.revert_button}
            // >
            //   <GenerateIcon />
            //   {t("Revert_transaction")}
            // </Button>
          )}
        </div>

      </div>
    )
  }

  const removeLocalStorAndRefresh = useCallback((err, redirectToLoginForm = false, showSnackbar = true) => {
    redirectToLoginForm && storeShowLoginForm()
    showSnackbar && enqueueSnackbar(t(capitalizeFirstLetter(err)))
    setTimeout(() => {
      removeToken()
      removeRefreshToken()
      removeDayState()
      removeSellerInfo()
      removeExpiredTime()
      removeShiftId()
      window.location.reload()
    }, 1000);
  }, [t])

  const onRevertCommision = async () => {
    setIsReverting(true);

    try {
      const body = {
        txId: trxId,
        amount: !revertSummData.isPartial
          ? trxInfo.amount
          : revertSummData.partialSumm,
        fullRefund: !revertSummData.isPartial,
        ccy: trxInfo.ccy,
        reasonCode: revertSummData.revertReason,
        reasonMessage: revertSummData.revertReasonDescription,
      };
      const { status, response } = await apiRequest(
        "post",
        `/pos/api/v1/tx/return`,
        body
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        if (data.result === "success") {
          enqueueSnackbar(t("Wait_for_a_trx_finish"), { variant: "warning" });
          // dispatch(setShowRevertContent(false))
          setTrxRequestId(data.requestId);
        } else if (data.result === "tx_not_found") {
          enqueueSnackbar(t("Trx_not_found"), { variant: "error" });
        } else if (data.result === "return_forbidden") {
          enqueueSnackbar(t("Trx_return_forbidden"), { variant: "warning" });
        } else if (data.result === "seller_is_inactive") {
          removeLocalStorAndRefresh("seller_is_inactive", true)
        } else if (data.result === "terminal_is_inactive") {
          removeTerminalId()
          removeTerminalLoginInfo()
          enqueueSnackbar(t("Terminal_is_inactive"), { variant: "warning" });
          removeLocalStorAndRefresh("terminal_is_inactive", false, false)
        } else if (data.result === "shift_not_open") {
          enqueueSnackbar(t("Shift_not_open"), { variant: "warning" });
        } else if (data.result === "external_error") {
          enqueueSnackbar(t("External_error"), { variant: "warning" });
        }
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch transaction details:", error);
    } finally {
      setIsReverting(false);
    }
  };

  const disabledButton =
    !revertSummData.revertReason ||
    !revertSummData.revertReasonDescription ||
    (revertSummData.isPartial && !revertSummData.partialSumm) ||
    (revertSummData.isPartial &&
      Number(revertSummData.partialSumm) > trxInfo.amount);

  const renderView = () => {
    return (
      <div className={styles.succuss_view_container}>
        <div className={styles.succuss_view_content}>
          <div className={styles.succuss_view_content_success_info}>
            {/* <div className={styles.succuss_view_content_logo}>
              <LogoSuccessIcon />
            </div> */}
            <div className={styles.succuss_view_content_info}>
              {/* {!showError ? <SuccessIcon /> : <ErrorIcon />} */}
              <div className={styles.success_title}>
                {/* {!showError ? t('Paid_successfully') : t("Payment_error")} */}
              </div>
              <div className={styles.success_description}>
                <div className={styles.modal_content}>
                  <div id="revert" className={styles.logout_header}>
                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="merchantId">{t("Reason")}</label>

                      <FormControl>
                        <InputLabel id="selectedDecision">{t("Select")}</InputLabel>
                        <Select
                          labelId="selectedDecision-label"
                          id="selectedDecision"
                          value={revertSummData.revertReason}
                          onChange={(e) => onChange("revertReason", e.target.value)}
                        >
                          {renderReasonsItems()}
                        </Select>
                      </FormControl>
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="selectedDecision">
                        {t("Description_solution")}
                      </label>
                      <FormControl>
                        <TextField
                          id="outlined-basic"
                          label={t("We_decided_to")}
                          multiline
                          variant="outlined"
                          value={revertSummData.revertReasonDescription}
                          onChange={(e) => {
                            const value = e.target.value.replace(/^\s+/, "");
                            onChange("revertReasonDescription", value);
                          }}
                        />
                      </FormControl>
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="merchantId">
                        {t("Is_partial_revert_summ")}
                      </label>
                      <Switch
                        // onChange={(e) => onChange("isPartial", e.target.checked)}
                        // checked={revertSummData.isPartial}
                        checked={false}
                      />
                    </div>

                    {revertSummData.isPartial && (
                      <div
                        className={classNames({
                          [styles.info_block_input_container]: true,
                        })}
                      >
                        <label htmlFor="selectedDecision">
                          {t("Transaction_partial_summ")}
                        </label>
                        <TextField
                          id="outlined-basic"
                          label={t("Amount")}
                          multiline
                          variant="outlined"
                          disabled={!revertSummData.isPartial}
                          value={revertSummData.partialSumm}
                          onChange={handleInputChange}
                          onPaste={handlePaste}
                          helperText={
                            trxInfo.amount < revertSummData.partialSumm &&
                            t("Partial_summ_big_that_transaction_summ")
                          }
                          error={trxInfo.amount < revertSummData.partialSumm}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <h4>
                  {amountFormatter(trxInfo.amount)} <span className={styles.uppercase}>{trxInfo.ccy}</span>
                </h4>

                <>
                  <div className={styles.success_line}>
                    <div>{t('Date')}:</div>
                    <div>{moment(trxInfo.date).format("HH:mm, DD.MM.YYYY")}</div>
                  </div>
                  <div className={styles.success_line}>
                    <div>{t('Transaction_id')}:</div>
                    <div>{trxId}</div>
                  </div>
                </>

              </div>
            </div>
          </div>
          <div className={styles.btn_container} >

            {trxRequestId && (
              <Box sx={{ width: "100%" }} className={styles.revert_loader} >
                <span className={styles.waiting_for_response}>
                  {t("Waiting_for_a_trx_revert_response")}
                  <CircularProgress size={16} />
                </span>
                <LinearProgress variant="determinate" value={checkCounter * 10} />
              </Box>
            )}

            <div
              className={classNames({
                [`${styles.cancel_action_button}`]: true,
                [`${styles.is_disabled}`]: isReverting || disabledButton || trxRequestId > 0
              })}
              onClick={() =>
                isReverting || disabledButton || trxRequestId > 0 ? {} : onRevertCommision()
              }
            >
              {t("Revert_summ")}
            </div>
          </div>
        </div>
      </div >
    )
  }

  const onChange = (state, value) => {
    let data = { ...revertSummData };
    if (state === "isPartial") {
      if (!value) {
        data = {
          ...revertSummData,
          partialSumm: "",
          [state]: value,
        };
        setRevertSummData(data);
      } else {
        data = {
          ...revertSummData,
          [state]: value,
        };
        setRevertSummData(data);
      }
    } else if (state === "partialSumm") {
      if ((value !== "-" || value === "") && value[value.length - 1] !== "-") {
        data = {
          ...revertSummData,
          partialSumm: "",
          [state]: value,
        };
        setRevertSummData(data);
      }
    } else {
      data = {
        ...revertSummData,
        [state]: value,
      };

      setRevertSummData(data);
    }
  };

  const renderReasonsItems = () => {
    const content = resonList.map(({ code, title }, index) => {
      return (
        <MenuItem value={code} key={index}>
          {title}{" "}
        </MenuItem>
      );
    });
    return content;
  };

  const transactionRevertContent = () => {
    return (
      <div>
        <div className={styles.transaction_revert}>
          {renderView()}
        </div>
      </div>
    )
  }

  const transactionContent = !showRevertContent ? transactionDetailsContent() : transactionRevertContent();
  const activeNav = !showRevertContent ? NAV.TRANSACTIONS_DETAILS : NAV.TRANSACTIONS_REVERT
  const type = !showRevertContent ? "" : TYPES.TRANSACTION_REVERT
  const backButtonUrl = "/app/transactions";

  return (
    <AppMainLayout {...{ backButtonUrl, activeNav, type }}>
      {redirect && (
        <Redirect
          to={`/app/transactions${redirectTrxId ? `/${redirectTrxId}` : ""}`}
        />
      )}
      {transactionContent}
    </AppMainLayout>
  );
};
export default TransactionsDetails;
