import {
  getToken,
  isTokenPresent,
} from "../services/Auth";
import {

} from "../actions";

const initialState = {
  isFetching: null,
  token: isTokenPresent() ? getToken() : "",

};

export default function TokenReducer(state = initialState, action) {
  switch (action.type) {
   
    default:
      return state;
  }
}
