import React from "react";

export const TerminalIcon = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.4782 7.75C17.4855 7.75 17.4927 7.75 17.5 7.75C17.5073 7.75 17.5145 7.75 17.5218 7.75C17.736 7.74999 17.9329 7.74998 18.0982 7.76126C18.2759 7.77338 18.4712 7.80099 18.6697 7.88321C19.0985 8.06083 19.4392 8.40151 19.6168 8.83031C19.699 9.02881 19.7266 9.22415 19.7387 9.40179C19.75 9.56712 19.75 9.76396 19.75 9.97824V10.0218C19.75 10.236 19.75 10.4329 19.7387 10.5982C19.7266 10.7759 19.699 10.9712 19.6168 11.1697C19.4392 11.5985 19.0985 11.9392 18.6697 12.1168C18.4712 12.199 18.2759 12.2266 18.0982 12.2387C17.9329 12.25 17.736 12.25 17.5218 12.25H17.4782C17.264 12.25 17.0671 12.25 16.9018 12.2387C16.7241 12.2266 16.5288 12.199 16.3303 12.1168C15.9015 11.9392 15.5608 11.5985 15.3832 11.1697C15.301 10.9712 15.2734 10.7759 15.2613 10.5982C15.25 10.4329 15.25 10.236 15.25 10.0218C15.25 10.0145 15.25 10.0073 15.25 10C15.25 9.99273 15.25 9.98548 15.25 9.97825C15.25 9.76397 15.25 9.56712 15.2613 9.40179C15.2734 9.22415 15.301 9.02881 15.3832 8.83031C15.5608 8.40151 15.9015 8.06083 16.3303 7.88321C16.5288 7.80099 16.7241 7.77338 16.9018 7.76126C17.0671 7.74998 17.264 7.74999 17.4782 7.75Z" fill="#A1ABB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.4782 0.250001C17.4855 0.250001 17.4927 0.250002 17.5 0.250002C17.5073 0.250002 17.5145 0.250001 17.5218 0.250001C17.736 0.249988 17.9329 0.249976 18.0982 0.261256C18.2759 0.273377 18.4712 0.30099 18.6697 0.383213C19.0985 0.560827 19.4392 0.901507 19.6168 1.33031C19.699 1.52881 19.7266 1.72415 19.7387 1.90179C19.75 2.06712 19.75 2.26396 19.75 2.47824V2.52176C19.75 2.73604 19.75 2.93289 19.7387 3.09821C19.7266 3.27585 19.699 3.47119 19.6168 3.6697C19.4392 4.0985 19.0985 4.43918 18.6697 4.61679C18.4712 4.69901 18.2759 4.72663 18.0982 4.73875C17.9329 4.75003 17.736 4.75002 17.5218 4.75H17.4782C17.264 4.75002 17.0671 4.75003 16.9018 4.73875C16.7241 4.72663 16.5288 4.69901 16.3303 4.61679C15.9015 4.43918 15.5608 4.0985 15.3832 3.6697C15.301 3.47119 15.2734 3.27585 15.2613 3.09821C15.25 2.93288 15.25 2.73604 15.25 2.52176C15.25 2.51453 15.25 2.50727 15.25 2.5C15.25 2.49273 15.25 2.48548 15.25 2.47825C15.25 2.26397 15.25 2.06712 15.2613 1.90179C15.2734 1.72415 15.301 1.52881 15.3832 1.33031C15.5608 0.901507 15.9015 0.560827 16.3303 0.383213C16.5288 0.30099 16.7241 0.273377 16.9018 0.261256C17.0671 0.249976 17.264 0.249988 17.4782 0.250001Z" fill="#A1ABB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.97825 15.25C9.98548 15.25 9.99273 15.25 10 15.25C10.0073 15.25 10.0145 15.25 10.0218 15.25C10.236 15.25 10.4329 15.25 10.5982 15.2613C10.7759 15.2734 10.9712 15.301 11.1697 15.3832C11.5985 15.5608 11.9392 15.9015 12.1168 16.3303C12.199 16.5288 12.2266 16.7241 12.2387 16.9018C12.25 17.0671 12.25 17.264 12.25 17.4782V17.5218C12.25 17.736 12.25 17.9329 12.2387 18.0982C12.2266 18.2759 12.199 18.4712 12.1168 18.6697C11.9392 19.0985 11.5985 19.4392 11.1697 19.6168C10.9712 19.699 10.7759 19.7266 10.5982 19.7387C10.4329 19.75 10.236 19.75 10.0218 19.75H9.97824C9.76396 19.75 9.56712 19.75 9.40179 19.7387C9.22415 19.7266 9.02881 19.699 8.83031 19.6168C8.40151 19.4392 8.06083 19.0985 7.88321 18.6697C7.80099 18.4712 7.77338 18.2759 7.76126 18.0982C7.74998 17.9329 7.74999 17.736 7.75 17.5218C7.75 17.5145 7.75 17.5073 7.75 17.5C7.75 17.4927 7.75 17.4855 7.75 17.4782C7.74999 17.264 7.74998 17.0671 7.76126 16.9018C7.77338 16.7241 7.80099 16.5288 7.88321 16.3303C8.06083 15.9015 8.40151 15.5608 8.83031 15.3832C9.02881 15.301 9.22415 15.2734 9.40179 15.2613C9.56712 15.25 9.76397 15.25 9.97825 15.25Z" fill="#A1ABB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.97825 7.75C9.98548 7.75 9.99273 7.75 10 7.75C10.0073 7.75 10.0145 7.75 10.0218 7.75C10.236 7.74999 10.4329 7.74998 10.5982 7.76126C10.7759 7.77338 10.9712 7.80099 11.1697 7.88321C11.5985 8.06083 11.9392 8.40151 12.1168 8.83031C12.199 9.02881 12.2266 9.22415 12.2387 9.40179C12.25 9.56712 12.25 9.76396 12.25 9.97824V10.0218C12.25 10.236 12.25 10.4329 12.2387 10.5982C12.2266 10.7759 12.199 10.9712 12.1168 11.1697C11.9392 11.5985 11.5985 11.9392 11.1697 12.1168C10.9712 12.199 10.7759 12.2266 10.5982 12.2387C10.4329 12.25 10.236 12.25 10.0218 12.25H9.97824C9.76396 12.25 9.56712 12.25 9.40179 12.2387C9.22415 12.2266 9.02881 12.199 8.83031 12.1168C8.40151 11.9392 8.06083 11.5985 7.88321 11.1697C7.80099 10.9712 7.77338 10.7759 7.76126 10.5982C7.74998 10.4329 7.74999 10.236 7.75 10.0218C7.75 10.0145 7.75 10.0073 7.75 10C7.75 9.99273 7.75 9.98548 7.75 9.97825C7.74999 9.76397 7.74998 9.56712 7.76126 9.40179C7.77338 9.22415 7.80099 9.02881 7.88321 8.83031C8.06083 8.40151 8.40151 8.06083 8.83031 7.88321C9.02881 7.80099 9.22415 7.77338 9.40179 7.76126C9.56712 7.74998 9.76397 7.74999 9.97825 7.75Z" fill="#A1ABB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.97825 0.250001C9.98548 0.250001 9.99273 0.250002 10 0.250002C10.0073 0.250002 10.0145 0.250001 10.0218 0.250001C10.236 0.249988 10.4329 0.249976 10.5982 0.261256C10.7759 0.273377 10.9712 0.30099 11.1697 0.383213C11.5985 0.560827 11.9392 0.901507 12.1168 1.33031C12.199 1.52881 12.2266 1.72415 12.2387 1.90179C12.25 2.06712 12.25 2.26396 12.25 2.47824V2.52176C12.25 2.73604 12.25 2.93289 12.2387 3.09821C12.2266 3.27585 12.199 3.47119 12.1168 3.6697C11.9392 4.0985 11.5985 4.43918 11.1697 4.61679C10.9712 4.69901 10.7759 4.72663 10.5982 4.73875C10.4329 4.75003 10.236 4.75002 10.0218 4.75H9.97824C9.76396 4.75002 9.56712 4.75003 9.40179 4.73875C9.22415 4.72663 9.02881 4.69901 8.83031 4.61679C8.40151 4.43918 8.06083 4.0985 7.88321 3.6697C7.80099 3.47119 7.77338 3.27585 7.76126 3.09821C7.74998 2.93288 7.74999 2.73604 7.75 2.52176C7.75 2.51453 7.75 2.50727 7.75 2.5C7.75 2.49273 7.75 2.48548 7.75 2.47825C7.74999 2.26397 7.74998 2.06712 7.76126 1.90179C7.77338 1.72415 7.80099 1.52881 7.88321 1.33031C8.06083 0.901507 8.40151 0.560827 8.83031 0.383213C9.02881 0.30099 9.22415 0.273377 9.40179 0.261256C9.56712 0.249976 9.76397 0.249988 9.97825 0.250001Z" fill="#A1ABB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.47825 7.75C2.48548 7.75 2.49273 7.75 2.5 7.75C2.50727 7.75 2.51453 7.75 2.52176 7.75C2.73604 7.74999 2.93288 7.74998 3.09821 7.76126C3.27585 7.77338 3.47119 7.80099 3.6697 7.88321C4.0985 8.06083 4.43918 8.40151 4.61679 8.83031C4.69901 9.02881 4.72663 9.22415 4.73875 9.40179C4.75003 9.56712 4.75002 9.76396 4.75 9.97824V10.0218C4.75002 10.236 4.75003 10.4329 4.73875 10.5982C4.72663 10.7759 4.69901 10.9712 4.61679 11.1697C4.43918 11.5985 4.0985 11.9392 3.6697 12.1168C3.47119 12.199 3.27585 12.2266 3.09821 12.2387C2.93289 12.25 2.73604 12.25 2.52176 12.25H2.47824C2.26396 12.25 2.06712 12.25 1.90179 12.2387C1.72415 12.2266 1.52881 12.199 1.33031 12.1168C0.901507 11.9392 0.560827 11.5985 0.383213 11.1697C0.30099 10.9712 0.273377 10.7759 0.261256 10.5982C0.249976 10.4329 0.249988 10.236 0.250001 10.0218C0.250001 10.0145 0.250002 10.0073 0.250002 10C0.250002 9.99273 0.250001 9.98548 0.250001 9.97825C0.249988 9.76397 0.249976 9.56712 0.261256 9.40179C0.273377 9.22415 0.30099 9.02881 0.383213 8.83031C0.560827 8.40151 0.901507 8.06083 1.33031 7.88321C1.52881 7.80099 1.72415 7.77338 1.90179 7.76126C2.06712 7.74998 2.26397 7.74999 2.47825 7.75Z" fill="#A1ABB2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.47825 0.250001C2.48548 0.250001 2.49273 0.250002 2.5 0.250002C2.50727 0.250002 2.51453 0.250001 2.52176 0.250001C2.73604 0.249988 2.93288 0.249976 3.09821 0.261256C3.27585 0.273377 3.47119 0.30099 3.6697 0.383213C4.0985 0.560827 4.43918 0.901507 4.61679 1.33031C4.69901 1.52881 4.72663 1.72415 4.73875 1.90179C4.75003 2.06712 4.75002 2.26396 4.75 2.47824V2.52176C4.75002 2.73604 4.75003 2.93289 4.73875 3.09821C4.72663 3.27585 4.69901 3.47119 4.61679 3.6697C4.43918 4.0985 4.0985 4.43918 3.6697 4.61679C3.47119 4.69901 3.27585 4.72663 3.09821 4.73875C2.93289 4.75003 2.73604 4.75002 2.52176 4.75H2.47824C2.26396 4.75002 2.06712 4.75003 1.90179 4.73875C1.72415 4.72663 1.52881 4.69901 1.33031 4.61679C0.901507 4.43918 0.560827 4.0985 0.383213 3.6697C0.30099 3.47119 0.273377 3.27585 0.261256 3.09821C0.249976 2.93288 0.249988 2.73604 0.250001 2.52176C0.250001 2.51453 0.250002 2.50727 0.250002 2.5C0.250002 2.49273 0.250001 2.48548 0.250001 2.47825C0.249988 2.26397 0.249976 2.06712 0.261256 1.90179C0.273377 1.72415 0.30099 1.52881 0.383213 1.33031C0.560827 0.901507 0.901507 0.560827 1.33031 0.383213C1.52881 0.30099 1.72415 0.273377 1.90179 0.261256C2.06712 0.249976 2.26397 0.249988 2.47825 0.250001Z" fill="#A1ABB2" />
        </svg>
    );
};
