import React, { useState, useEffect } from "react";
import classNames from "classnames"
import { setShowBackButton, setShowNavbarPanel } from "../../api/actionCreators/mainActionCreator"
import { getCurrentLanguage } from "../../api/selectors/languageSelector"
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { NAV, qrList } from "../../constants"
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api/services/Api"
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from 'notistack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TransitionProps } from '@mui/material/transitions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// import { createServer } from 'miragejs';
// import authHandler from '../../api/miragejs/handlers/authHandler';
import Button from '@mui/material/Button';
import {
  setStoreQrSettings
} from "../../api/services/Auth";

import styles from "./qr-settings.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// createServer({
//   routes() {
//     const mockBaseUrl = 'http://localhost:3002/';
//     authHandler(this, mockBaseUrl)
//     this.passthrough()
//   }
// })

const QrSettings = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();

  const language = useSelector(getCurrentLanguage);
  const [selectedQr, setSelectedQr] = useState("");
  const [selectedQrAmount, setSelectedQrAmount] = useState("");
  const [selectedQrOld, setSelectedQrOld] = useState("");
  const [selectedQrAmountOld, setSelectedQrAmountOld] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [showApproveModal, setShowApproveModal] = useState(false);

  useEffect(() => {
    const qrSettings = JSON.parse(localStorage.getItem("_qr_settings"))

    setSelectedQr(qrSettings.currentType)
    setSelectedQrAmount(qrSettings.currentAmountType)
    setSelectedQrOld(qrSettings.currentType)
    setSelectedQrAmountOld(qrSettings.currentAmountType)
    setIsLoading(false)

    dispatch(setShowBackButton(true))
    dispatch(setShowNavbarPanel(false))

    // axios
    //   .get(`/pos/api/v1/settings`)
    //   .then(res => {
    //     const { status } = res
    //     if (status === 200 || status === 201 || status === 204) {
    //       console.log("res", res.data.result)
    //       if (res.data.result === "success") {
    //         setSelectedQr(res.data.qrSettings.qrType)
    //         setSelectedQrAmount(res.data.qrSettings.qrAmountType)
    //         setSelectedQrOld(res.data.qrSettings.qrType)
    //         setSelectedQrAmountOld(res.data.qrSettings.qrAmountType)
    //         setIsLoading(false)
    //       }
    //     } else if (status === 400) {
    //       enqueueSnackbar(t("Error_400"), { variant: "error" });
    //     } else if (status === 401) {
    //       enqueueSnackbar(t("Error_401"), { variant: "error" });
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 3000);
    //     } else if (status === 404) {
    //       enqueueSnackbar(t("Error_404"), { variant: "error" });
    //     } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
    //       enqueueSnackbar(t("Error_500"), { variant: "error" });
    //     }
    //   }).catch(err => {
    //     setIsLoading(false)
    //     enqueueSnackbar(t("Error_500"), { variant: "error" });
    //   }).finally(() => {
    //     setIsLoading(false)
    //     enqueueSnackbar(t("Error_500"), { variant: "error" });
    //   })


  }, [dispatch, t])

  useEffect(() => () => { //componentWillUnmount
    dispatch(setShowBackButton(false))
    dispatch(setShowNavbarPanel(true))
  }, [dispatch]);

  const saveQrSettings = () => {
    setIsLoading(true)
    setShowApproveModal(false)
    setTimeout(() => {


      const params = {
        qrType: selectedQr,
        qrAmountType: selectedQrAmount || "fixed"
      }


      axios
        .post(`/pos/api/v1/qr-settings`, { ...params })
        .then((res) => {
          const { status
            , data 
          } = res
          if ((status === 200 || status === 201 || status === 204) 
            && data && data.result === "success"
          ) {

            const storeQr = {
              currentType: selectedQr,
              currentAmountType: selectedQrAmount,
            }
            setStoreQrSettings(JSON.stringify(storeQr))
            enqueueSnackbar(t(`Qr_code_was_changed_${selectedQr}`), { variant: "success" })
            history.goBack()
          } 
          else if ((status === 200 || status === 201 || status === 204) && data && data.result === "exist_active_qr") {

            const qrSettings = JSON.parse(localStorage.getItem("_qr_settings"))

            setSelectedQr(qrSettings.currentType)
            setSelectedQrAmount(qrSettings.currentAmountType)
            setSelectedQrOld(qrSettings.currentType)
            setSelectedQrAmountOld(qrSettings.currentAmountType)

            enqueueSnackbar(t("Exist_active_qr_settings"), { variant: "warning" });
          } 
          else if (status === 400) {
            enqueueSnackbar(t("Error_400"), { variant: "error" });
          } else if (status === 401) {
            enqueueSnackbar(t("Error_401"), { variant: "error" });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else if (status === 404) {
            enqueueSnackbar(t("Error_404"), { variant: "error" });
          } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
          }


        }).catch(err => {
          setIsLoading(false)
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }).finally(() => {
          setIsLoading(false)
        })
    }, 1000);
  };

  const stateWithNoChanges = isLoading || !selectedQr || !selectedQrAmount || (selectedQr === selectedQrOld && selectedQrAmount === selectedQrAmountOld)



  const confirmChangesModal = () => {
    //session_is_not_active" || result === "terminal_is_not_active

    return (
      <Dialog
        open={showApproveModal}
        TransitionComponent={Transition}
        keepMounted
        className={classNames({
          [`${styles.modal_blur_bg}`]: true,
        })}

        onClose={() => setShowApproveModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <span className={styles.modal_title}> {t("Confirm_changes")}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span className={styles.modal_description}>
              {t("Confirm_changes_description")}

            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={() => setShowApproveModal(false)} variant="text">
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
            })}
            >{t("Cancel")}</span>
          </Button>
          <Button onClick={() => saveQrSettings()} variant="text">
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
              [`${styles.confirm_btn}`]: true,
            })}
            >{t("Confirm")}</span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderAmountsList = (list) => {
    const listContent = list.map(({ amountLabel, amountValue }, index) => {
      return (
        <div key={index} className={styles.qr_amount_line_container}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="amountValues"
              className={classNames({
                [`${styles.qr_line}`]: true,
                [`${styles.qr_amount_line}`]: true,
                [`${styles.disabled_state}`]: isLoading,
              })}
              key={index}
              onClick={() => setSelectedQrAmount(amountValue)}
            >
              <FormControlLabel value={amountValue} control={<Radio />} label={t(amountLabel)} checked={amountValue === selectedQrAmount} />
            </RadioGroup>
          </FormControl>
        </div>
      )
    })

    return listContent
  }

  const onChangeQrValue = (value) => {

    value === "dynamic" ? setSelectedQrAmount('fixed') : setSelectedQrAmount('');
    setSelectedQr(value)
  }

  const renderQrSettings = () => {


    const content = qrList.map((oneQr, index) => {
      const { label, description, amountList, value } = oneQr
      return (

        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="qrList"
            className={classNames({
              [`${styles.qr_line}`]: true,
              [`${styles.disabled_state}`]: isLoading,
            })}
            key={index}
            onClick={() => { onChangeQrValue(value) }}
          >
            <FormControlLabel value={value} control={<Radio id={`QR_SETTING_OPTION_${index}`}/>} label={t(label)} checked={value === selectedQr} />
            <div className={styles.qr_description}>
              {t(description)}
            </div>
          </RadioGroup>

          {value === selectedQr && renderAmountsList(amountList)}
        </FormControl>

        // <div
        //   className={classNames({
        //     [`${styles.qr_line}`]: true,
        //     [`${styles.disabled_state}`]: isLoading,
        //   })}
        //   key={index}
        //   onClick={() => setSelectedQr("ro")}
        // >
        //   <div className={styles.qr_code_container}>
        //     {t(label)}
        //     <div className={styles.qr_description}>
        //       {t(description)}
        //     </div>
        //   </div>

        //   <div className={styles.amounts_list}>
        //     {renderAmountsList(amountList)}
        //   </div>
        // </div>
      )
    })

    return content
  }

  return (
    <AppMainLayout activeNav={NAV.QR_SETTINGS} backButtonUrl={"/app/profile"}>
      {confirmChangesModal()}
      <div className={styles.qr_code_container}>
        <div className={
          classNames({
            [`${styles.no_content}`]: isLoading,
          })}>
          {isLoading ? <CircularProgress className="circular-progress" size={22} /> : renderQrSettings()}
        </div>

        {!isLoading && <div className={styles.btn_container} onClick={() => stateWithNoChanges ? {} : setShowApproveModal(true)}>
          <div
            className={classNames({
              [`${styles.day_action_button}`]: true,
              [`${styles.disabled_button}`]: isLoading || stateWithNoChanges,
              [`${styles.button_no_changes}`]: language === selectedQr,
            })}
          >
            {!isLoading && t("Apply")}
            {isLoading && <CircularProgress className="circular-progress" size={22} />}
          </div>
        </div>}


      </div>
    </AppMainLayout >
  );
};
export default QrSettings;
