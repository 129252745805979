import React, { useEffect } from "react";
import classNames from "classnames"
import { setShowBackButton, setShowNavbarPanel } from "../../api/actionCreators/mainActionCreator"
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { NAV } from "../../constants"
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./support.module.scss";

const Support = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setShowBackButton(true))
    dispatch(setShowNavbarPanel(false))
  }, [dispatch])

  useEffect(() => () => {
    dispatch(setShowBackButton(false))
    dispatch(setShowNavbarPanel(true))
  }, [dispatch]);

  return (
    <AppMainLayout activeNav={NAV.SUPPORT} backButtonUrl={"/app/profile"}>
      <div className={styles.transaction_details_container}>

        <div
          className={classNames({
            [`${styles.transaction_details_line}`]: true,
          })}
        >
          <div className={styles.transaction_line_container}>
            {t("Phone")}
          </div>
          <div className={styles.bold}>
            <a href="tel:+37368584828"> +373 (68) 584 828</a>
           
          </div>

        </div>

        <div
          className={classNames({
            [`${styles.transaction_details_line}`]: true,
          })}
        >
          <div className={styles.transaction_line_container}>
            Email
          </div>
          <div className={styles.bold}>
          <a href="mailto:support@finergy.tech">support@finergy.tech</a> 
          </div>

        </div>

      </div>
    </AppMainLayout>
  );
};
export default Support;
