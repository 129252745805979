import { RootState } from '../../interfaces'

const getMainReducerState = (state: RootState) => {
    return state?.MainReducer;
};

export const getShowBackButon = (state: RootState) => {
    return getMainReducerState(state).showBackButton;
};

export const getShowReloadButon = (state: RootState) => {
    return getMainReducerState(state).showReloadButon;
};

export const getLogoutCounter = (state: RootState) => {
    return getMainReducerState(state).logoutCounter;
};

export const getRefreshTransactionsList = (state: RootState) => {
    return getMainReducerState(state).refreshTransactionsList;
};

export const getDayStatus = (state: RootState) => {
    return getMainReducerState(state).dayIsOpen;
};

export const getShowRevertContent = (state: RootState) => {
    return getMainReducerState(state).showRevertContent;
};

export const getShowModalOpenCloseDay = (state: RootState) => {
    return getMainReducerState(state).showModalOpenCloseDay;
};

export const getShowModalLogout = (state: RootState) => {
    return getMainReducerState(state).showModalLogout;
};

export const getNavBarState = (state: RootState) => {
    return getMainReducerState(state).showNavbarPanel;
};

export const getToken = (state: RootState) => {
    return getMainReducerState(state).token;
};