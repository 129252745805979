import React from 'react';

export const UserSupportIcon = () => {
    return (
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1667 7.50371C14.1667 7.2157 14.1667 7.07169 14.2101 6.94331C14.3361 6.57037 14.6682 6.42563 15.001 6.27407C15.3751 6.10368 15.5621 6.01849 15.7474 6.0035C15.9578 5.98649 16.1686 6.03181 16.3484 6.13274C16.5868 6.26654 16.7531 6.52078 16.9233 6.72751C17.7095 7.68237 18.1025 8.1598 18.2464 8.6863C18.3624 9.11117 18.3624 9.55549 18.2464 9.98037C18.0366 10.7483 17.3738 11.392 16.8832 11.9878C16.6323 12.2926 16.5068 12.445 16.3484 12.5339C16.1686 12.6349 15.9578 12.6802 15.7474 12.6632C15.5621 12.6482 15.3751 12.563 15.001 12.3926C14.6682 12.241 14.3361 12.0963 14.2101 11.7234C14.1667 11.595 14.1667 11.451 14.1667 11.163L14.1667 7.50371Z" stroke="#A1ABB2" strokeWidth="1.5" />
            <path d="M5.8335 7.50385C5.8335 7.14114 5.82331 6.81517 5.53009 6.56017C5.42344 6.46741 5.28204 6.40301 4.99925 6.27421C4.62517 6.10382 4.43813 6.01863 4.2528 6.00364C3.69676 5.95867 3.3976 6.33817 3.07693 6.72765C2.29079 7.68251 1.89771 8.15994 1.75388 8.68643C1.63781 9.11131 1.63781 9.55563 1.75388 9.98051C1.96367 10.7484 2.62643 11.3921 3.11701 11.988C3.42624 12.3636 3.72164 12.7063 4.2528 12.6633C4.43813 12.6483 4.62517 12.5631 4.99925 12.3927C5.28204 12.2639 5.42344 12.1995 5.53009 12.1068C5.82331 11.8518 5.8335 11.5258 5.8335 11.1631L5.8335 7.50385Z" stroke="#A1ABB2" strokeWidth="1.5" />
            <path d="M4.16675 6C4.16675 3.23858 6.77842 1 10.0001 1C13.2217 1 15.8334 3.23858 15.8334 6" stroke="#A1ABB2" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
            <path d="M15.8335 12.6667V13.3334C15.8335 14.8062 14.3411 16.0001 12.5002 16.0001H10.8335" stroke="#A1ABB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};