/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { IAppMainLayoutProps } from "./types";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { getShowBackButon, getShowModalOpenCloseDay, getNavBarState, getShowReloadButon, getShowModalLogout } from "../../../api/selectors/mainSelector"
import {
  setShowModalOpenCloseDay,
  setDayStatus,
  setRefreshTransactionsList,
  setShowModalLogout,
  setShowRevertContent,
} from "../../../api/actionCreators/mainActionCreator"
import { BackButtonIcon } from "../../../svg-icons/icon-back-button"
import { TransactionsIcon } from "../../../svg-icons/icon-transactions"
import { ProfileIcon } from "../../../svg-icons/icon-profile"
import { TerminalIcon } from "../../../svg-icons/icon-terminal"
import { ReloadIcon } from "../../../svg-icons/icon-reload"
import { NAV, capitalizeFirstLetter, actions, miaPosEvents, TYPES } from "../../../constants"
import { downloadPdfFile } from "../../../hooks"
import moment from "moment"
import { Link, Redirect } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "../../../api/services/Api"
import { useTranslation } from "react-i18next";
import { LogoutIcon } from "../../../svg-icons/icon-logout"
import {
  authEnd,
  setExpiredTime,
  setTokenInHeaders,
  setTokenExpired,
} from "../../../api/actionCreators/authActionCreator";
import {
  storeDayState,
  removeToken,
  removeTerminalId,
  storeShiftId,
  removeRefreshToken,
  removeDayState,
  removeSellerInfo,
  removeExpiredTime,
  removeShiftId,
  saveRequestPayment,
  removeTerminalLoginInfo,
  removeRequestPayment,
  // storeTerminalError,
} from "../../../api/services/Auth";
import MiaPos from "../../../_bridge/init_miapos"
// import AndroidBridgeMock from "../../../_bridge/miapos-bridge-mock"

import styles from "./app-main-layout.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AppMainLayout({
  children,
  activeNav,
  terminalId,
  removeLocalStorAndRefresh,
  backButtonUrl,
  showExitButton = false,
  type = ""
}: IAppMainLayoutProps) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false)
  const [isChanging, setIsChanging] = useState(false)

  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)

  const [changingText, setChangingText] = useState("")
  const [redirectUrl, setRedirectUrl] = useState("/app/terminal")

  const sessionToken = localStorage.getItem('_session_token');
  const refreshToken = localStorage.getItem('_refresh_token');
  // const terminalError = localStorage.getItem('_terminal_error');
  const terminalError = localStorage.getItem('_terminal_error');

  const showBackButton = useSelector(getShowBackButon);
  const showReloadButton = useSelector(getShowReloadButon);
  const showNavbar = useSelector(getNavBarState);
  const showModalOpenCloseDay = useSelector(getShowModalOpenCloseDay);
  const showModalLogout = useSelector(getShowModalLogout);
  // const isDayOpen = useSelector(getDayStatus);
  const isDayOpen = localStorage.getItem('_day_state') === "opened";
  const { SAVE_FILE, GET_EXTERNAL_PAYMENT_REQUEST } = miaPosEvents
  const redirectToLoginPages = () => {
    terminalError === "terminal_is_not_active" && removeTerminalLoginInfo()
    removeToken()
    removeDayState()
    window.history.replaceState(null, null, "/");
    window.location.reload()
  }

  // const bridgeMock = new AndroidBridgeMock();
  // bridgeMock.setMockResponse(GET_EXTERNAL_PAYMENT_REQUEST, { amount: 123, requestId: "333", currency: "MDL" });

  useEffect(() => {

    const platformType = localStorage.getItem("x_platform_type");
    if (platformType === "web") {
      removeRequestPayment()
    } else {
      MiaPos.send(GET_EXTERNAL_PAYMENT_REQUEST).then(res => {
        if (!!res) {
          saveRequestPayment(JSON.stringify(res))
        } else {
          removeRequestPayment();
        }
      })
    }


    // const requestParam = { // for test
    //   requestId: "testtesttesttesttesttesttesttesttest", amount: 951, currency: "mdl"
    // }
    // saveRequestPayment(JSON.stringify(requestParam))

  }, [])

  useEffect(() => () => {
    showSessionExpiredModal && setTimeout(() => {
      redirectToLoginPages()
    }, 3000);
  }, [showSessionExpiredModal])

  useEffect(() => {
    !sessionToken && setRedirectUrl("/app")
    !sessionToken && setRedirect(true);
  }, [sessionToken, isDayOpen]);

  useEffect(() => {
    const interval = setInterval(() => {
      terminalError && setShowSessionExpiredModal(true)
    }, 1000);
    return () => clearInterval(interval);


  }, [terminalError]);

  const onLogout = () => {


    setIsChanging(true)

    axios
      .post("/pos/api/v1/seller/logout", { refreshToken })
      .then(res => {
        removeToken()
        removeRefreshToken()
        removeDayState()
        removeSellerInfo()
        removeExpiredTime()
        removeShiftId()
        // removeTerminalId()


        dispatch(setExpiredTime(""));
        dispatch(authEnd());
        dispatch(setTokenInHeaders(false));
        dispatch(setTokenExpired(true));
        dispatch(setShowModalLogout(false))
        // dispatch(setDayStatus(!isDayOpen))
        // dispatch(setShowModalLogout(false))
        // dispatch(setShowModalOpenCloseDay(false))
        //setIsChanging(false)

        // window.location.reload()


      })
      .catch(err => {
        ///this.handleHttpError("isSaving", err.response);
      });
  }

  const downloadZreport = (shiftId) => {
    axios
      .get(`/pos/api/v1/shift/${shiftId}/z-report`)
      .then(res => {
        const { result, report } = res.data
        if (result === "success") {
          const date = moment().format("DD-MM-YYYY--HH-mm");
          const platformType = localStorage.getItem("x_platform_type");
          const isWeb = platformType === "web"

          if (isWeb) {
            downloadPdfFile(report, `z-report-${date}`, "pdf")
          } else {

            const params = {
              filename: `z-report-${date}.pdf`,
              base64: report,
            }
            MiaPos.send(SAVE_FILE, { ...params }).then(res => {
              !res.result && enqueueSnackbar(t('File_not_saved'), { variant: 'error' })
            })
          }
        } else {
          enqueueSnackbar(t(capitalizeFirstLetter(result)))
        }
      })
      .catch(err => {
      });
  }


  const onActionDayOpenClose = (action) => {
    setIsChanging(true)
    const notification = isDayOpen ? t("Day_is_successfully_closed") : t("Day_is_successfully_opened")
    isDayOpen ? setChangingText(t("Closing_the_day")) : setChangingText(t("Opening_the_day"))



    const url = isDayOpen ? "/pos/api/v1/shift/close" : "/pos/api/v1/shift/open"

    if (!isDayOpen && showModalLogout) {
      onLogout()
    }

    if (action === actions.OPEN_CLOSE_DAY || (isDayOpen && showModalLogout)) {
      axios
        .post(url)
        .then(res => {
          const { result, shiftId = null } = res.data
          if (result === "success") {
            const dayState = isDayOpen ? "closed" : "opened"
            isDayOpen && downloadZreport(shiftId)
            dispatch(setDayStatus(!isDayOpen))
            storeDayState(dayState)
            storeShiftId(shiftId)
            //storeDayState(dayState)
            // isDayOpen && removeDayState()
            // isDayOpen && removeShiftId()
            // !isDayOpen && 

            setTimeout(() => {
              if (showModalLogout) {
                onLogout()
              } else {
                enqueueSnackbar(notification)
                setRedirect(true)
                dispatch(setDayStatus(!isDayOpen))
                dispatch(setShowModalOpenCloseDay(false))
                setIsChanging(false)
              }

            }, 1000);
          }
          else if (result === "session_ended" || result === "not_found") {
            // console.log("result", result)

            setShowSessionExpiredModal(true)
            setIsChanging(false)
            removeLocalStorAndRefresh(result, true, false)
            //  window.location.reload();
          } else if (result === "terminal_is_not_active") {
            removeTerminalId()
            removeTerminalLoginInfo()
            removeLocalStorAndRefresh("terminal_is_inactive", false, false)
          } else if (result === "already_closed" || result === "already_opened") {
            setRedirect(true)
            setIsChanging(false)
            dispatch(setShowModalOpenCloseDay(false))
          } else if (result === "shift_not_belongs_to_user") {
            setIsChanging(false)
            dispatch(setShowModalOpenCloseDay(false))
            const text = `${t("Already_open_for")} ${res.data.username}. ${t("Close_shift")}`
            enqueueSnackbar(text)
          }
          // dispatch(setShowModalOpenCloseDay(state === "opened"))

        })
        .catch(() => {
          setIsChanging(false)
          dispatch(setShowModalOpenCloseDay(false))
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          ///this.handleHttpError("isSaving", err.response);
        });

    }



  }

  const modalDescription = !isDayOpen ? t("Are_you_sure_to_open_day") : t("Are_you_sure_to_closed_day")
  const modalTitle = !isDayOpen ? t("Confirm_opening") : t("Confirm_closing")

  const renderDialogOpenDay = () => {
    return (
      <Dialog
        open={showModalOpenCloseDay}
        TransitionComponent={Transition}
        keepMounted

        className={classNames({
          [`${styles.modal_blur_bg}`]: true,
          [`${styles.is_changing}`]: isChanging,
        })}

        onClose={() => isChanging ? {} : dispatch(setShowModalOpenCloseDay(false))}
        aria-describedby="alert-dialog-slide-description"
      >

        {isChanging ? <>
          <div className={styles.status}>

            <CircularProgress size={90} />
            {changingText}
          </div>
        </> : <>
          <DialogTitle>
            <span className={styles.modal_title}>{modalTitle}</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">

              <span className={styles.modal_description}>{modalDescription}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dispatch(setShowModalOpenCloseDay(false))} variant="text">
              <span className={classNames({
                [`${styles.modal_btn}`]: true,
              })}
              >{t("Back")}</span>
            </Button>
            <Button onClick={() => onActionDayOpenClose(actions.OPEN_CLOSE_DAY)} variant="text">
              <span className={classNames({
                [`${styles.modal_btn}`]: true,
                [`${styles.modal_accept}`]: true,
              })}
              >{t("Confirm")}</span>
            </Button>
          </DialogActions>
        </>}

      </Dialog>
    )
  }


  const renderLogoutModal = () => {
    return (
      <Dialog
        open={showModalLogout}
        TransitionComponent={Transition}
        keepMounted

        className={classNames({
          [`${styles.modal_blur_bg}`]: true,
          // [`${styles.is_changing}`]: isChanging,
        })}

        onClose={() => isChanging ? {} : dispatch(setShowModalLogout(false))}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <span className={styles.modal_title}>{t("Approve_exit")}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

            <span className={styles.modal_description}>{t("Approve_exit_description")}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(setShowModalLogout(false))} variant="text" disabled={isChanging}>
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
            })}
            >{t("Cancel")}</span>
          </Button>
          <Button onClick={() => isChanging ? {} : onActionDayOpenClose(actions.LOGOUT)} disabled={isChanging} variant="text">
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
              [`${styles.modal_accept}`]: true,
              [`${styles.modal_disabled_btn}`]: isChanging,
            })}
            >{t("Logout")}
              {isChanging && <CircularProgress size={16} />}
            </span>
          </Button>
        </DialogActions>


      </Dialog>
    )
  }



  const sessionExpiredModal = () => {
    //session_is_not_active" || result === "terminal_is_not_active
    const text = terminalError === "terminal_is_not_active" ? "terminal_is_not_active" : "session_is_not_active"
    return (
      <Dialog
        open={showSessionExpiredModal}
        TransitionComponent={Transition}
        keepMounted

        className={classNames({
          [`${styles.modal_blur_bg}`]: true,
        })}

        onClose={() => isChanging ? {} : dispatch(setShowModalOpenCloseDay(false))}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <span className={styles.modal_title}>{t('Alert_title')}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span className={styles.modal_description}>
              {t(capitalizeFirstLetter(text))}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => redirectToLoginPages()} variant="text">
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
            })}
            >{"OK"}</span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderNavbarPanel = () => {
    return (
      <div className={styles.app_navigation_container}>
        <div className={styles.app_navigation}>

          <div
            className={classNames({
              [`${styles.nav_transactions}`]: true,
              [`${styles.nav_active}`]: activeNav === NAV.TRANSACTIONS,
            })}
          >
          </div>

          <Link
            className={classNames({
              [`${styles.nav_terminals}`]: true,
              [`${styles.nav_active}`]: activeNav === NAV.TERMINAL,
            })}
            underline="none"
            key={1}
            to={`/app/terminal`}
          >
            <div className={styles.svg_container}>
              <TerminalIcon className={""} />
            </div>
            <span>{t('Terminal')}</span>
          </Link>
          <Link
            className={classNames({
              [`${styles.nav_transactions}`]: true,
              [`${styles.nav_active}`]: activeNav === NAV.TRANSACTIONS,
            })}
            underline="none"
            key={2}
            to={`/app/transactions`}
          >
            <div className={styles.svg_container}>
              <TransactionsIcon className={""} />
            </div>

            <span>{t('Transactions')}</span>
          </Link>


          <Link
            className={classNames({
              [`${styles.nav_profile}`]: true,
              [`${styles.nav_active}`]: activeNav === NAV.PROFILE,
            })}
            underline="none"
            key={3}
            to={`/app/profile`}
          >
            <div className={styles.svg_container}>
              <ProfileIcon className={""} />
            </div>
            <span>{t('Profile')}</span>
          </Link>

        </div>
        {/* </div> */}
      </div>
    )
  }

  return (

    <div
      className={classNames({
        [`${styles.main_wrapper}`]: true,

      })}
    >
      {redirect && <Redirect to={redirectUrl} />}
      {renderDialogOpenDay()}
      {renderLogoutModal()}
      {showSessionExpiredModal && sessionExpiredModal()}
      <div className={styles.app_title_container}>

        <div className={styles.app_title}>
          {type && type === TYPES.TRANSACTION_REVERT ?
            <BackButtonIcon onClick={() => dispatch(setShowRevertContent(false))} className={styles.back_button} id={"APP_BACK_BUTTON"} /> :
            showBackButton &&
            <Link to={backButtonUrl}>
              <BackButtonIcon onClick={() => { }} className={styles.back_button} id={"APP_BACK_BUTTON"} />
            </Link>
          }
          {t(activeNav)}
          {terminalId && <div className={styles.terminal_id}>(id: {terminalId})</div>}
        </div>

        {showReloadButton &&
          <ReloadIcon onClick={() => dispatch(setRefreshTransactionsList(true))} className={styles.refresh_button} />
        }

        {showExitButton &&
          <div
            className={classNames({
              [`${styles.user_line}`]: true,
              [`${styles.logout_line}`]: true,
              [`${styles.bold}`]: true,
            })}
            onClick={() => dispatch(setShowModalLogout(true))}
          >
            <div className={styles.user_icon_container}>
              <LogoutIcon />
            </div>
          </div>
        }
      </div>

      <div
        className={classNames({
          [`${styles.app_content}`]: true,
          [`${styles.change_max_height}`]: !showNavbar,
          [`${styles.scroll_y}`]: activeNav === NAV.TRANSACTIONS,
        })}
      >
        {children}
      </div>
      {showNavbar && renderNavbarPanel()}
    </div>
  );
}

export default AppMainLayout;
