
import React from 'react';

export const QrCodeMenuIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 5.5C2.5 4.32149 2.5 3.73223 2.86612 3.36612C3.23223 3 3.82149 3 5 3C6.17851 3 6.76777 3 7.13388 3.36612C7.5 3.73223 7.5 4.32149 7.5 5.5C7.5 6.67851 7.5 7.26777 7.13388 7.63388C6.76777 8 6.17851 8 5 8C3.82149 8 3.23223 8 2.86612 7.63388C2.5 7.26777 2.5 6.67851 2.5 5.5Z" stroke="#A1ABB2" strokeWidth="1.5" />
            <path d="M2.5 15.5C2.5 14.3215 2.5 13.7322 2.86612 13.3661C3.23223 13 3.82149 13 5 13C6.17851 13 6.76777 13 7.13388 13.3661C7.5 13.7322 7.5 14.3215 7.5 15.5C7.5 16.6785 7.5 17.2678 7.13388 17.6339C6.76777 18 6.17851 18 5 18C3.82149 18 3.23223 18 2.86612 17.6339C2.5 17.2678 2.5 16.6785 2.5 15.5Z" stroke="#A1ABB2" strokeWidth="1.5" />
            <path d="M2.5 10.5L7.5 10.5" stroke="#A1ABB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 3L10 7.16667" stroke="#A1ABB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5 5.5C12.5 4.32149 12.5 3.73223 12.8661 3.36612C13.2322 3 13.8215 3 15 3C16.1785 3 16.7678 3 17.1339 3.36612C17.5 3.73223 17.5 4.32149 17.5 5.5C17.5 6.67851 17.5 7.26777 17.1339 7.63388C16.7678 8 16.1785 8 15 8C13.8215 8 13.2322 8 12.8661 7.63388C12.5 7.26777 12.5 6.67851 12.5 5.5Z" stroke="#A1ABB2" strokeWidth="1.5" />
            <path d="M17.5 10.5L12.5 10.5C11.3215 10.5 10.7322 10.5 10.3661 10.8661C10 11.2322 10 11.8215 10 13M10 15.3077V17.6154M12.5 13V14.25C12.5 15.4553 13.153 15.5 14.1667 15.5C14.6269 15.5 15 15.8731 15 16.3333M13.3333 18H12.5M15 13C16.1785 13 16.7678 13 17.1339 13.3666C17.5 13.7333 17.5 14.3234 17.5 15.5036C17.5 16.6838 17.5 17.2739 17.1339 17.6406C16.8667 17.9082 16.4806 17.9805 15.8333 18" stroke="#A1ABB2" strokeWidth="1.5" strokeLinecap="round" />
        </svg>


    );
};
