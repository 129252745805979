import {
    SET_AUTH_DATA,
    AUTH_START,
    AUTH_STOP,
    FETCH_TOKEN_SUCCESS,
    LOGOUT_START,
    AUTH_FINISH,
    SET_EXPIRED_TIME,
    SET_PAGE_RELOADED,
    SET_TOKEN_IN_HEADERS,
    CHECK_IF_TOKEN_EXPIRED,
    SET_MODE_STATUS,
    SET_IS_AUTH_FETCHING
} from '../actions';

export const signIn = (authData: any) => {
    return { type: SET_AUTH_DATA, payload: authData };
};

export const authStart = () => {
    return { type: AUTH_START };
};

export const setTokenInHeaders = (state) => {
    return { type: SET_TOKEN_IN_HEADERS, payload: state };
};

export const setIsAuthFetching = (state) => {
    return { type: SET_IS_AUTH_FETCHING, payload: state };
}

export const setTokenExpired = (state = false) => {
    return { type: CHECK_IF_TOKEN_EXPIRED, payload: state };
};

export const authEnd = () => {
    return { type: AUTH_STOP };
};

export const logoutStart = () => {
    return { type: LOGOUT_START };
};
export const logoutFinish = () => {
    return { type: AUTH_FINISH };
};

export const setTokenTerminalId = (tokenData) => {
    return { type: FETCH_TOKEN_SUCCESS, payload: tokenData };
};

export const setPageReloaded = (state = false) => {
    return { type: SET_PAGE_RELOADED, payload: state };
};

export const setExpiredTime = (expiredTime) => {
    return { type: SET_EXPIRED_TIME, payload: expiredTime };
};

export const setModeStatus = (status) => {
    return { type: SET_MODE_STATUS, payload: status };
};