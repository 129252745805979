import React from "react";

export const TransactionsIcon = ({ className }) => {
    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5L2.65856 5C1.65277 5 1.14987 5 1.02472 4.69134C0.899565 4.38268 1.25517 4.01942 1.96637 3.29289L4.21091 1" stroke="#A1ABB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 11L13.3414 11C14.3472 11 14.8501 11 14.9753 11.3087C15.1004 11.6173 14.7448 11.9806 14.0336 12.7071L11.7891 15" stroke="#A1ABB2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};
