
// Singleton Bridge object
var MiaPosBridge = (function () {
  // Private variables and functions can be defined here
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  function guid() {
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  }

  // This is the instance of the Singleton
  var instance;

  // The actual Singleton constructor
  function init() {
    // Private methods and variables
    var promises = {};

    // Public methods and variables
    return {
      // Define your methods and properties here
      handleJsApiPromiseResult: function (e) {
         console.log("handleJsApiPromiseResult(): " + JSON.stringify(e))

         var promise = promises[e.promiseId];
         if (!promise) return;

         if (e.status === 'RESOLVE') {
           promise.resolve(e.data);
         } else {
           promise.reject(e.data);
         }
         delete promises[e.promiseId];
       },

       send: function (eventName, params) {
           var promiseId = guid();
           return new Promise(function (resolve, reject) {
               promises[promiseId] = {
                 resolve: resolve,
                 reject: reject
               };

               try {
                    if (window.webkit) {
                        const obj = {promiseId: promiseId, eventType: eventName, params: params}
                        window.webkit.messageHandlers.bridge.postMessage(JSON.stringify(obj))
                    } else if (window.AndroidBridge) {
                        window.AndroidBridge.send(
                             promiseId,
                             eventName,
                             JSON.stringify(params),
                        );
                    } else {
                        //reject({ error_type: "UnsupportedPlatform" })
                        delete promises[promiseId];
                   }
               } catch (e) {
                 reject({ error_type: "InternalJsError", error_data: e });
                 delete promises[promiseId];
               }
             });
         },
    };
  }

  // Get the Singleton instance or create it if it doesn't exist
  return {
    getInstance: function () {
      if (!instance) {
        instance = init();
      }
      return instance;
    }
  };
})();

// Create a Bridge instance
var MiaPos = MiaPosBridge.getInstance();
window.MiaPos = MiaPos;

export default MiaPos