export const convertDecimalNumber = (number) => {
    let numberString = number.toString();
    let decimalPointIndex = numberString.indexOf('.');
    let formattedNumberString = decimalPointIndex === -1 ? numberString : numberString.substring(0, decimalPointIndex + 3);

    return formattedNumberString
}

export const downloadPdfFile = (contract, customerName, fileType) => {
    if (!contract) {
        console.log("return")
        return;
    }

    const fileName = `${customerName || 'unknown'}.${fileType}`;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // to fix EI11 issue
        const byteCharacters = atob(contract);
        let byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        window.navigator.msSaveOrOpenBlob(new Blob([byteArray], { type: `application/${fileType}` }), fileName);
    } else {
        const linkSource = `data:application/${fileType};base64,${contract}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
};