

import React from 'react';

export const NextIcon = () => {
    return (
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L7.53033 6.96967C7.82322 7.26256 7.82322 7.73744 7.53033 8.03033L1.53033 14.0303C1.23744 14.3232 0.762563 14.3232 0.46967 14.0303C0.176777 13.7374 0.176777 13.2626 0.46967 12.9697L5.93934 7.5L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967Z" fill="#C7C5CD" />
        </svg>
    );
};